import React from 'react';
import { ReactComponent as DininIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';
import { ReactComponent as CardIcon } from '../../../Assets/card.svg';
import { ReactComponent as CashIcon } from '../../../Assets/cash.svg';
import { ReactComponent as UpiIcon } from '../../../Assets/upi.svg';
import { ReactComponent as CashBackIcon } from '../../../Assets/cashback.svg';
import {
    formatDateAndRemoveDash,
    toSentenceCase,
    toSentenceCaseWithSpecialCharacter,
} from '../../../Helper/HelperFunctions';

export default function OrderSummery(props) {
    const { orderDetails, isNew, orderItems, page, selectedOrderDetail, orderDetail, kotOrderDetail, popupDetails } =
        props;
    // const sliceNumber = Math.ceil(orderDetails?.length / 2);
    // const firstColumn = orderDetails?.slice(0, sliceNumber);
    // const secondColumn = orderDetails?.slice(sliceNumber);
    const PaymentIcon = (payment_method) => {
        switch (payment_method) {
            case 'UPI' || 'UPI_INTENT':
                return <UpiIcon style={{ marginRight: 4 }} />;
            case 'CASH_ON_DELIVERY':
                return <CashIcon style={{ marginRight: 4 }} />;
            case 'CREDIT_CARD/DEBIT_CARD':
                return <CardIcon style={{ marginRight: 4 }} />;
            case 'CREDIT_CARD':
                return <CardIcon style={{ marginRight: 4 }} />;
            case 'DEBIT_CARD':
                return <CardIcon style={{ marginRight: 4 }} />;
            default:
                return <CardIcon style={{ marginRight: 4 }} />;
        }
    };
    return (
        <>
            <div
                className={`flex flex-row justify-between mt-[1rem] mb-[1rem] border-neutral-300 md:block ${
                    orderDetails?.length || (selectedOrderDetail?.length && 'border-b')
                }`}>
                <div className='flex flex-col gap-[0.75rem]'>
                    <span className='text-[#706E7E]'>
                        Order Bill Name :
                        <span className='text-[#131126] ml-2'>
                            {kotOrderDetail?.customer?.name ||
                                selectedOrderDetail?.customer?.name ||
                                orderDetail?.customer?.name ||
                                popupDetails?.order_bill_name ||
                                popupDetails?.bill_name ||
                                'N/A'}
                        </span>
                    </span>
                    <span className='text-[#706E7E]'>
                        Order Date :{' '}
                        <span className='text-[#131126] ml-2'>
                            {formatDateAndRemoveDash(
                                kotOrderDetail?.status?.placed_at ||
                                    selectedOrderDetail?.status?.placed_at ||
                                    orderDetail?.status?.placed_at ||
                                    popupDetails?.order_date
                            ) || ''}
                        </span>
                    </span>
                    <span className='text-[#706E7E]'>
                        Order Type :{' '}
                        <span className='text-[#131126] ml-2'>
                            {toSentenceCaseWithSpecialCharacter(
                                kotOrderDetail?.ordering_mode ||
                                    selectedOrderDetail?.ordering_mode ||
                                    orderDetail?.ordering_mode ||
                                    popupDetails?.order_type ||
                                    ''
                            )}
                        </span>
                    </span>
                    {/* <span>Order Taken By : {orderDetails?.ordering_mode_details?.display_name || ''}</span> */}
                    {page !== 'abandonedCarts' && <span className='text-[#706E7E]'>Order Via : -- </span>}
                </div>

                <div className='flex flex-col gap-[0.75rem] md:mt-3'>
                    {/* <span>Customer's order : {orderDetails?.ordering_mode_details?.display_name || ''}</span> */}
                    <span className='text-[#706E7E]'>
                        Order Time :{' '}
                        <span className='text-[#131126] ml-2'>
                            {kotOrderDetail?.status?.placed_at ||
                                selectedOrderDetail?.status?.placed_at ||
                                orderDetail?.status?.placed_at ||
                                popupDetails?.order_time ||
                                ''}
                        </span>
                    </span>
                    {page !== 'abandonedCarts' && (
                        <span className='flex flex-row text-[#706E7E]'>
                            Payment Method :{' '}
                            <span className='text-[#131126] flex flex-row items-center ml-2'>
                                {PaymentIcon(
                                    kotOrderDetail?.payment_method?.payment_method ||
                                        selectedOrderDetail?.payment_method?.payment_method ||
                                        orderDetail?.payment_method?.payment_method ||
                                        popupDetails?.payment_method ||
                                        ''
                                )}
                                {toSentenceCaseWithSpecialCharacter(
                                    kotOrderDetail?.payment_method?.payment_method ||
                                        selectedOrderDetail?.payment_method?.payment_method ||
                                        orderDetail?.payment_method?.payment_method ||
                                        popupDetails?.payment_method ||
                                        ''
                                ) || '--'}
                            </span>
                        </span>
                    )}
                    <span className='text-[#706E7E]'>
                        {page === 'abandonedCarts' ? 'Dishes in cart' : 'Dishes Ordered'} :{' '}
                        {popupDetails?.dishes_ordered || popupDetails?.dishes_in_cart || '--'}
                    </span>
                    <span className='text-[#706E7E]'>Platform : {toSentenceCase(popupDetails?.platform) || '--'} </span>
                </div>
            </div>
        </>
    );
}

export const Item = ({ item, value, isNew, actionComponent }) => {
    const icons = {
        'Takeaway': <TakeAwayIcon />,
        'Cash': <CashIcon />,
        'Card': <CardIcon />,
        'Credit card': <CardIcon />,
        'Debit card': <CardIcon />,
        'UPI': <UpiIcon />,
        'Delivery': <DeliveryIcon />,
        'Dine-In': <DininIcon />,
        'UPI_INTENT': <UpiIcon />,
        'Balance': <CashBackIcon />,
    };

    const icon = icons[value];

    return (
        <div className='my-1.5 flex flex-row'>
            <span className='paragraph-medium-medium text-neutral-500'>{item}:</span>

            <div className='flex flex-row items-center ml-2'>
                {icon && <span className='mr-1'>{icon}</span>}
                <span className='paragraph-medium-regular'>{value}</span>
                {isNew && (
                    <span className='font-medium text-[10px] leading-3 px-2 py-1 border-primary-500 text-primary-500 bg-primary-50 rounded border ml-1'>
                        New
                    </span>
                )}
                {actionComponent}
            </div>
        </div>
    );
};
