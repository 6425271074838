import React, { useRef, useState } from 'react';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import { ReactComponent as TrashIcon } from '../../../Assets/trash.svg';

export const NotAssignedRider = (props) => {
    const { orderDetail } = props;
    const viewOptionRef = useRef(null);
    const [showViewDropdown, setShowViewDropdown] = useState(false);
    const [ViewHelpServices, setViewHelpServices] = useState(false);
    useOutsideClick(viewOptionRef, () => setShowViewDropdown(false));
    const viewControllerDropdown = [{ item: 'Help', onClick: () => setViewHelpServices(true) }];
    const renderViewDropdown = () => (
        // !isMobileScreen && (
        <div className='relative'>
            <div
                className='flex flex-col items-center justify-center gap-1 cursor-pointer p-2 -m-1'
                onClick={() => setShowViewDropdown((prev) => !prev)}>
                {[...Array(3)].map((_, i) => (
                    <div
                        key={i}
                        className='bg-black h-[3px] w-[3px] rounded-full'
                    />
                ))}
            </div>
            {showViewDropdown && (
                <div
                    className='border border-neutral-300 rounded-xl px-4 w-[152px] absolute top-6 bg-white right-0'
                    ref={viewOptionRef}>
                    {viewControllerDropdown.map((el, index) => (
                        <div
                            key={index}
                            className='py-1.5 cursor-pointer paragraph-medium-regular'
                            onClick={() => {
                                el.onClick();
                                setShowViewDropdown(false);
                            }}>
                            <span>{el.item}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    return (
        <div className='px-2 mt-2 mb-5 bg-[#FAFAFA] rounded-xl h-[7.3rem]'>
            <div className='flex flex-row justify-between text-2xl  pb-[0.75rem] pt-[0.75rem] mx-2'>
                <div className='flex flex-row'>
                    <TrashIcon stroke='#6C5DD3' />
                    <span className='mx-3 paragraph-large-medium'>Auto Rider Assignment in Effect</span>
                </div>
                <div className='flex flex-row'>{renderViewDropdown()}</div>
            </div>
            <p className='px-2 mt-2 mb-2 paragraph-medium-medium '>
                More information will be shown once the rider is allotted to the order.
            </p>
            {/* {ViewHelpServices && (
                <ViewHelpServicesPopup
                    handleClickClose={() => setViewHelpServices(false)}
                    backToPage='Order Details'
                    services={helpServices}
                />
            )} */}
        </div>
    );
};
