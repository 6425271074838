import React, { useEffect, useState } from 'react';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';

export default function IndividualTableRow(props) {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        !props.isShowBulkSelectButton && setIsSelected(false);
    }, [props.isShowBulkSelectButton]);

    const handleClickCustomerName = () => {
        props.handleClickCustomerName();

        if (!props.isShowBulkSelectButton) {
            return;
        }
        setIsSelected(!isSelected);
    };

    return (
        <tr
            className={`paragraph-small-regular ${props.index !== 0 && 'border-t'} justify-center h-[70px] ${
                isSelected ? 'bg-primary-50 border border-primary-500' : 'even:bg-neutral-50 border-neutral-300'
            }`}>
            <td className={`px-6 ${props.isShowBulkSelectButton ? 'cursor-pointer' : ''}`}>
                <div className='flex flex-row items-center'>
                    {props.isShowBulkSelectButton && (
                        <div className='mr-4 cursor-pointer'>
                            <CheckBoxWithoutLabels
                                onChange={(isChecked) => props.handleCheckboxChange(props.el.id, isChecked)}
                                isChecked={props.checkedItems.includes(props.el.id)}
                            />
                        </div>
                    )}
                    <span
                        className='cursor-pointer text-primary-500 hover:underline underline-offset-3'
                        onClick={() => props.handleClickName(props.el.id)}>
                        {props.el.name}{' '}
                    </span>
                </div>
            </td>

            <td className='px-6'>{props.el.mobile_number}</td>
            <td className='px-6 cursor-pointer'>{props.el.email ? props.el.email : '--'}</td>
            <td className='px-6'>{props.el.order_count}</td>
            <td className='px-6'>{props.el.life_time_value}</td>
            <td className='px-6'>{props.el.last_order}</td>
        </tr>
    );
}
