import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import APIV5 from '../../../api/axios/APIV5';

const TotalRevenue = ({ selectedDates, outletId, defaultParams, selectedRestaurantId }) => {
    const [totalRevenueData, setTotalRevenueData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!outletId || !selectedRestaurantId) {
            console.warn('Outlet ID or Restaurant ID is missing. Skipping API call.');
            return; // Exit early if either value is missing
        }
        const fetchPlatformSalesData = async () => {
            const defaultParamsData = defaultParams();
            try {
                setIsLoading(true);
                const response = await APIV5.get(`restaurants/${selectedRestaurantId}/total-revenue`, {
                    params: {
                        ...defaultParamsData,
                        outlet_id: [outletId],
                    },
                });
                setTotalRevenueData(response);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPlatformSalesData();
    }, [selectedDates, outletId, selectedRestaurantId, defaultParams]);

    const formatChartData = () => {
        const data = totalRevenueData?.total_revenue || [];

        if (!data.length) {
            return {
                series: [
                    { name: 'Online sales', data: [] },
                    { name: 'Offline sales', data: [] },
                ],
                categories: [],
            };
        }

        const isDateBased = !!data[0]?.date;

        return {
            series: [
                {
                    name: 'Online sales',
                    data: data.map((item) => item.online_sale || 0),
                },
                {
                    name: 'Offline sales',
                    data: data.map((item) => item.offline_sale || 0),
                },
            ],
            categories: isDateBased ? data.map((item) => item.day.slice(0, 3)) : data.map((item) => item.name),
        };
    };

    // Defensive rendering
    const renderChart = () => {
        const chartData = formatChartData();

        // Ensure series and categories are defined
        const safeChartOptions = {
            ...chartOptions,
            xaxis: {
                ...chartOptions.xaxis,
                categories: chartData.categories.length ? chartData.categories : [''],
            },
        };

        return (
            <ReactApexChart
                options={safeChartOptions}
                series={chartData.series}
                type='bar'
                height={250}
            />
        );
    };

    const chartOptions = {
        chart: {
            type: 'bar',
            height: '100%',
            width: '100%',
            toolbar: {
                show: false,
            },
        },

        stroke: {
            colors: ['transparent'],
            width: 5,
        },

        bar: {
            columnWidth: '16px',
        },

        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                borderRadius: 4,
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                },
            },

            states: {
                hover: {
                    enabled: false,
                },
            },
        },

        dataLabels: {
            enabled: false,
        },

        xaxis: {
            categories: formatChartData()?.categories,
            labels: {
                offsetY: 4,
                style: {
                    fontSize: '16px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    cssClass: 'text-sm',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },

        colors: ['#74C2B9', '#FFBB3C'],
        fill: {
            opacity: 1,
        },

        legend: {
            show: false,
        },

        grid: {
            padding: {
                top: 0,
            },
            borderColor: '#D3D2D8',
        },

        yaxis: {
            tickAmount: 5,
            labels: {
                formatter: (value) => {
                    if (value >= 1000) {
                        return `${(value / 1000).toFixed(1)}k`;
                    }
                    return value ?? '';
                },
                style: {
                    fontSize: '16px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    cssClass: 'text-sm',
                },
            },
        },

        tooltip: {
            enabled: false,
        },
    };

    return (
        <>
            <span className='paragraph-large-medium text-black'>Total revenue</span>
            <div className='border border-neutral-300 rounded-md mt-4'>
                <div className='mt-2 mb-1.5'>{!isLoading && renderChart()}</div>

                <div className='flex flex-row items-center w-full justify-center mb-5'>
                    <div className='flex flex-row items-center mr-3'>
                        <div className='h-3 w-3 bg-[#74C2B9] rounded-full mr-2.5' />
                        <span className='paragraph-small-regular'>Online sales</span>
                    </div>

                    <div className='flex flex-row items-center pl-3'>
                        <div className='h-3 w-3 bg-[#FFBB3C] rounded-full mr-2.5' />
                        <span className='paragraph-small-regular'>Offline sales</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TotalRevenue;
