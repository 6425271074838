import React, { useEffect, useState } from 'react';
import { ReactComponent as DeliveryIcon } from '../../../../Assets/riders.svg';
import { ReactComponent as DininIcon } from '../../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../../Assets/orders.svg';
import { ReactComponent as UPIIcon } from '../../../../Assets/upi.svg';
import { ReactComponent as CashIcon } from '../../../../Assets/cash.svg';
import { ReactComponent as CardIcon } from '../../../../Assets/card.svg';
import { OrderStatus } from '../Components/TableComponent';
import ListviewCustomerDetails from '../Components/ListviewCustomerDetails';
import ContentControllerSection from '../Components/ContentControllerSection';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { fetchOrderingModeCount, fetchOrders } from '../../Helper/functions';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';

export default function PastOrder(props) {
    const {
        setShowPopup,
        isMobileScreen,
        handleClickViewDetails,
        isFetchingOrders,
        setIsFetchingOrder,
        orderType,
        selectedOrderingMode,
        selectedFilter,
        setAllOrderingModeCounts,
        paginationState,
        isLoading,
        setLoading,
        setPaginationState,
        setOrderDetail,
    } = props;
    const today = new Date();
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [selectedDates, setSelectedDates] = useState([today, today]);
    const [orders, setOrders] = useState([]);
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    // const [isLoading, setLoading] = useState(true);

    const fetchData = async () => {
        setIsFetchingOrder(true);
        setLoading(true);
        try {
            const response = await fetchOrders({
                orderType,
                startDate: format(selectedDates[0], 'yyyy-MM-dd'),
                endDate: format(selectedDates[1], 'yyyy-MM-dd'),
                selectedOrderingMode,
                selectedOutletId,
                selectedRestaurantId,
                selectedFilter,
                perPage: paginationState.perPage,
                page: paginationState.currentPage,
            });
            if (response?.order_collection?.orders) {
                setPaginationState({
                    currentPage: response.order_collection.orders.current_page,
                    lastPage: response.order_collection.orders.last_page,
                    perPage: response.order_collection.orders.per_page,
                });
                setOrders(response?.order_collection?.orders?.data ?? response);
            } else {
                console.error('Unexpected response structure:', response);
                setOrders([]);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setIsFetchingOrder(false);
            setLoading(false);
        }
    };

    const orderingModeCount = async () => {
        const response = await fetchOrderingModeCount({
            orderType,
            startDate: format(selectedDates[0], 'yyyy-MM-dd'),
            endDate: format(selectedDates[1], 'yyyy-MM-dd'),
            setLoading,
            selectedOrderingMode,
            selectedOutletId,
            selectedRestaurantId,
            selectedFilter,
            perPage: paginationState.perPage,
            page: paginationState.currentPage,
        });
        if (response) {
            setAllOrderingModeCounts(response?.order_counts);
        } else {
            console.error('Unexpected response structure:', response);
        }
    };

    useEffect(() => {
        fetchData();
        orderingModeCount();
    }, [
        paginationState.currentPage,
        paginationState.perPage,
        selectedDates,
        selectedFilter,
        orderType,
        selectedOrderingMode,
        selectedOutletId,
        selectedRestaurantId,
        setIsFetchingOrder,
        setPaginationState,
    ]);
    const orderTypeIcon = (orderingMode) => {
        switch (orderingMode) {
            case 'Delivery':
                return <DeliveryIcon />;
            case 'Dine-in':
                return <DininIcon />;
            default:
                return <TakeAwayIcon />;
        }
    };

    const paymentModeIcon = (paymentMode) => {
        switch (paymentMode) {
            case 'UPI':
                return <UPIIcon />;
            case 'Cash':
                return <CashIcon />;
            default:
                return <CardIcon />;
        }
    };

    const handleClickOrder = (el) => {
        setShowPopup('orderDetails');
        setOrderDetail(el);
    };
    return (
        <>
            <ContentControllerSection
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
            />

            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 uppercase shadow-innerShadow paragraph-overline-small text-neutral-700 pl-6 h-11'>
                            <th className='min-w-[234px] pl-6 text-left'>CUSTOMER NAME</th>
                            <th className='min-w-[174px] pl-6 text-left'>ORDER DATE</th>
                            <th className='min-w-[166px] pl-6 text-left'>ORDER TYPE</th>
                            <th className='min-w-[191px] pl-6 text-left'>ORDER STATUS</th>
                            <th className='min-w-[163px] pl-6 text-left'>ITEMS ORDERED</th>
                            <th className='min-w-[162px] pl-6 text-left'>ORDER AMOUNT</th>
                            <th className='min-w-[220px] pl-6 text-left'>PAYMENT MODE</th>
                        </tr>
                    </thead>

                    {isFetchingOrders || isLoading ? (
                        <tbody>
                            <TableLoader
                                columns={7}
                                numberOfRow={3}
                            />
                        </tbody>
                    ) : orders.length > 0 ? (
                        <tbody>
                            {orders?.map((el, index) => {
                                const orderIcon = orderTypeIcon(el?.ordering_mode);
                                const paymentIcon = paymentModeIcon(el?.payment_method?.payment_method);

                                return (
                                    <tr
                                        key={index}
                                        className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'>
                                        <td
                                            className='pl-6 cursor-pointer text-primary-500 hover:underline'
                                            onClick={() => handleClickOrder(el)}>
                                            {el.customer.name}
                                        </td>

                                        <td className='pl-6'>{el.status.placed_at}</td>

                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center'>
                                                {orderIcon}
                                                <span className='pl-1'>{el?.ordering_mode}</span>
                                            </div>
                                        </td>

                                        <td className='pl-6'>
                                            <OrderStatus
                                                status={[
                                                    el?.status?.delivered_at !== null ||
                                                    el?.status?.customer_picked_up_at !== null ||
                                                    el?.status?.rider_delivered_at !== null
                                                        ? 'Delivered'
                                                        : 'Cancelled',
                                                ]}
                                            />
                                            {el.refunds?.length !== 0 && (
                                                <OrderStatus
                                                    status={[
                                                        el?.refunds?.[0]?.initiated_at !== null
                                                            ? 'Refund initiated'
                                                            : el?.refunds?.[0]?.refunded_at !== null
                                                              ? 'Refund success'
                                                              : el?.refunds?.[0]?.failed_at !== null
                                                                ? 'Refund failed'
                                                                : null,
                                                    ]}
                                                />
                                            )}
                                        </td>

                                        <td className='pl-6'>{el?.cart?.cart_items?.length}</td>
                                        <td className='pl-6'>{el.net_total}</td>

                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center'>
                                                {paymentIcon}
                                                <span className='pl-1'>{el?.payment_method?.payment_method}</span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={7}>
                                    <TableBlankComponentView previewText={'No Content Available'} />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>

            <div className='my-4'>
                <PaginationWithNumber
                    lastPage={paginationState.lastPage}
                    selectedPage={paginationState.currentPage}
                    setSelectedPage={(value) => {
                        setPaginationState({
                            currentPage: value,
                            lastPage: paginationState.lastPage,
                            perPage: paginationState.perPage,
                        });
                    }}
                    setResultPerPage={(value) => {
                        setPaginationState({
                            currentPage: paginationState.currentPage,
                            lastPage: paginationState.lastPage,
                            perPage: value,
                        });
                    }}
                    selectedResultPerpage={paginationState.perPage}
                />
            </div>
        </>
    );
}
