import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import PlatformSalesDetails from './PlatformSalesDetails';
import APIV5 from '../../../api/axios/APIV5';

export default function PlatformSales({ selectedDates, outletId, defaultParams, selectedRestaurantId }) {
    const [platformSalesData, setPlatformSalesData] = useState({ platform_stats: [] });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!outletId || !selectedRestaurantId) {
            console.warn('Outlet ID or Restaurant ID is missing. Skipping API call.');
            return;
        }
        const fetchPlatformSalesData = async () => {
            const defaultParamsData = defaultParams();
            try {
                setIsLoading(true);
                const response = await APIV5.get(`restaurants/${selectedRestaurantId}/platform-sales`, {
                    params: {
                        ...defaultParamsData,
                        outlet_ids: [outletId],
                    },
                });
                setPlatformSalesData(response?.platform_sales);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching platform sales data:', error);
            }
        };
        fetchPlatformSalesData();
    }, [selectedDates, outletId, selectedRestaurantId]);

    const platformNames = platformSalesData?.platform_stats?.map((item) => item.platform_name || '') || [];

    const salesPercentages = platformSalesData?.platform_stats?.map((item) => item.percentage || 0) || [];
    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        chart: {
            width: 'auto',
            height: 'auto',
        },
        labels: platformNames.length > 0 ? platformNames : ['No Data'],
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val) {
                // Add null check here
                return val ? `${parseInt(val)}%` : '0%';
            },
        },
        colors: ['#FFBB3C', '#4B39C9', '#DC2626', '#74C2B9', '#504F5A'],
        stroke: {
            colors: ['transparent'],
        },
    };

    const renderChart = () => {
        return (
            <Chart
                className='m-auto'
                type='donut'
                width={280}
                height={280}
                series={salesPercentages.length > 0 ? salesPercentages : [0]}
                options={chartOptions}
            />
        );
    };

    return (
        <div>
            <span className='paragraph-large-medium text-black'>Platform Sales</span>
            <div className='rounded-md border border-neutral-300 mt-4 py-2 min-h-[321px] xl:px-0.5'>
                <div className='xl:overflow-auto scrollbar-style py-[19px]'>
                    {platformSalesData?.platform_stats?.length > 0 ? (
                        <div className='flex justify-between items-center w-full xl:w-[550px] lg:w-full lg:block'>
                            <div className='w-[280px] lg:w-full lg:flex lg:mb-5'>{!isLoading && renderChart()}</div>
                            <div className='w-full pl-[22px] pr-6 pageContentSmall:pl-0 pageContentSmall:pr-3 lg:pl-5 lg:pr-6 mobile:px-3'>
                                <PlatformSalesDetails
                                    platformSalesData={platformSalesData}
                                    selectedDates={selectedDates}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='w-full h-64 border-neutral-300 rounded-lg overflow-auto flex justify-center items-center [&::-webkit-scrollbar]:hidden'>
                            <div className='text-center'>
                                <span className='text-lg font-bold'>No Content Available</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
