import React, { useEffect, useState } from 'react';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import ListViewOutletMenu from '../Catalogue/Components/ListViewOutletMenu';
import { ReactComponent as MenuIcon } from '../../../../Assets/menu.svg';
import { fetchOutletMenu } from '../../functions';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';

export default function OutletMenu() {
    const [tableDetails, setTableDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const handleClickOutletName = () => {
        navigate('/menu/outlet-menu', { state: { page: 'outlet-menu' } });
    };

    const isMobileScreen = IsMobileScreen();
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fetchOutletMenu();
            setTableDetails(response?.menus || []);
        } catch (error) {
            console.error('Error fetching outlet menu:', error);
            setTableDetails([]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            {!isMobileScreen ? (
                <div className='mb-4'>
                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[289px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        OUTLET NAME
                                    </th>
                                    <th className='px-6 min-w-[132px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        STATE
                                    </th>
                                    <th className='px-6 min-w-[109px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CITY
                                    </th>
                                    <th className='px-6 min-w-[284px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        ADDRESS
                                    </th>
                                    <th className='px-6 min-w-[223px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        PRESET ATTACHED
                                    </th>
                                    <th className='px-6 min-w-[233px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CATEGORIES AND DISHES
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={6}>
                                            <TableLoader
                                                hasSwitch={false}
                                                columns={6}
                                            />
                                        </td>
                                    </tr>
                                ) : tableDetails && tableDetails.length > 0 ? (
                                    tableDetails.map((el, index) => (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                            key={index}>
                                            <td
                                                className='px-6 cursor-pointer'
                                                onClick={handleClickOutletName}>
                                                {el.outlet?.name?.[1]}
                                            </td>
                                            <td className='px-6'>{el?.translations?.state?.[1]}</td>
                                            <td className='px-6'>{el?.translations?.city?.[1]}</td>
                                            <td className='px-6'>
                                                {el?.translations?.address_line_1?.[1] +
                                                    el?.translations?.address_line_2?.[1]}
                                            </td>
                                            <td className='px-6'>{el.presetAttached || 'N/A'}</td>
                                            <td className='px-6'>
                                                <div className='flex flex-col'>
                                                    <div className='flex flex-row items-center mb-1'>
                                                        <MenuIcon />
                                                        <span className='paragraph-small-regular ml-2'>
                                                            Categories -{el.categories}
                                                        </span>
                                                    </div>
                                                    <div className='flex flex-row items-center'>
                                                        <MenuIcon />
                                                        <span className='paragraph-small-regular ml-2'>
                                                            Dishes -{el.dishes}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6}>
                                            <TableBlankComponentView previewText={'No Content Available'} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className=''>
                    {isLoading ? (
                        <TableLoader
                            hasSwitch={false}
                            columns={1}
                        />
                    ) : tableDetails && tableDetails.length > 0 ? (
                        tableDetails.map((el, index) => (
                            <div
                                className='mt-2'
                                key={index}>
                                <ListViewOutletMenu
                                    outletName={el.outletName}
                                    state={el.state}
                                    city={el.city}
                                    address={el.address}
                                    presetAttached={el.presetAttached}
                                    categories={el.categories}
                                    dishes={el.dishes}
                                    handleClickViewDetails={handleClickOutletName}
                                />
                            </div>
                        ))
                    ) : (
                        <TableBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}

            {!isMobileScreen && tableDetails.length > 0 && <PaginationWithNumber />}
        </>
    );
}
