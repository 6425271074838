import React, { useState } from 'react';
import { Tab } from '../../../Components/Tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import PendingTableSection from './Complaint/PendingTableSection';
import ResolvedTableSection from './Complaint/ResolvedTableSection';

export default function Complaint(props) {
    const {
        setShowComplaintDetails,
        setShowResolvedComplaintPopup,
        setShowComplaintImage,
        selectedOutletId,
        selectedRestaurantId,
    } = props;

    const tabs = [
        { label: 'Pending complaint', path: '/order-rating/complaint/pending', module: 'pending' },
        { label: 'Resolved complaint', path: '/order-rating/complaint/resolved', module: 'resolved' },
    ];

    const { complaint } = useParams();
    const [orderPendingDetails, setOrderPendingDetails] = useState([]);

    const navigate = useNavigate();

    return (
        <>
            <div className='flex flex-row items-center gap-4 my-4'>
                {tabs?.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className='max-w-fit'
                            onClick={() => navigate(el.path)}>
                            <Tab
                                badgeText={index === 0 && orderPendingDetails?.total}
                                label={el.label}
                                isActive={complaint === el.module}
                            />
                        </div>
                    );
                })}
            </div>

            {complaint === tabs[0]?.module ? (
                <PendingTableSection
                    orderPendingDetails={orderPendingDetails}
                    setOrderPendingDetails={setOrderPendingDetails}
                    selectedRestaurantId={selectedRestaurantId}
                    selectedOutletId={selectedOutletId}
                    setShowComplaintImage={setShowComplaintImage}
                    setShowComplaintDetails={setShowComplaintDetails}
                />
            ) : (
                <ResolvedTableSection
                    selectedRestaurantId={selectedRestaurantId}
                    selectedOutletId={selectedOutletId}
                    setShowComplaintImage={setShowComplaintImage}
                    setShowResolvedComplaintPopup={setShowResolvedComplaintPopup}
                />
            )}
        </>
    );
}
