import { useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import BasicDetails from './BasicDetails';
import ExposeDish from './ExposeDish';
import DishPricing from './DishPricing';
import Badges from './Badges';
import LinkedAddonGroup from './LinkedAddonGroup';
import DishTiming from './DishTiming';
import UpSellItem from './UpSellItem';
import UpSellCurrentDish from './UpSellCurrentDish';
import { getDishDetails } from '../../../../functions';
import { DishDetailLoader } from './Loader';

export default function DishDetailsSection(props) {
    const { selectedCategoryDish } = props;

    const dishDetailsList = [
        BasicDetails,
        ExposeDish,
        DishPricing,
        Badges,
        LinkedAddonGroup,
        DishTiming,
        UpSellItem,
        UpSellCurrentDish,
    ];

    const [isLoading, setIsLoading] = useState(true);

    const [dishDetails, setDishDetails] = useState({});

    const getAllDishDetails = async () => {
        setIsLoading(true);
        const response = await getDishDetails(selectedCategoryDish.dish);

        setIsLoading(false);

        setDishDetails(response);
    };

    useEffect(() => {
        !!selectedCategoryDish.dish && getAllDishDetails();
    }, [selectedCategoryDish.dish]);

    const screenSize = useWindowSize();

    return (
        <>
            {isLoading ? (
                <DishDetailLoader />
            ) : (
                <div
                    className='overflow-auto scrollbar-style -mr-8 pr-8'
                    style={{ height: screenSize.height - 300 }}>
                    {dishDetailsList.map((Component, index) => (
                        <div
                            className='mb-4'
                            key={index}>
                            <Component
                                dishDetails={dishDetails}
                                selectedCategoryDish={selectedCategoryDish}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
