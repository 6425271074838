import React from 'react';
import { CheckBoxWithoutLabels } from '../../../../../Components/FormControl/FormControls';
import TableLoader from '../../../../../Components/ContentLoader/TableLoader';
import { BlankComponentTable } from '../../../../../Components/BlankComponent/BlankComponent';

export default function TableSection(props) {
    const {
        list,
        isSelectionEnable,
        selectedMediaList,
        setSelectedMediaList,
        setUserDetail,
        isLoading,
        setShowUploadPopup,
    } = props;
    const handleSelection = (id) => {
        const updatedList = selectedMediaList.includes(id)
            ? selectedMediaList.filter((item) => item !== id)
            : [...selectedMediaList, id];

        setSelectedMediaList(updatedList);
    };

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full break-words table-row-bg'>
                    <thead>
                        <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                            <th className='shadow-innerShadow px-6 min-w-[492px] lg:min-w-[326px] paragraph-overline-small text-neutral-700 sticky left-0 z-[4] bg-neutral-50'>
                                IMAGE TITLE
                            </th>
                            <th className='shadow-innerShadow px-6 min-w-[171px] lg:min-w-[132px] paragraph-overline-small text-neutral-700 sticky left-[326px] bg-neutral-50'>
                                CATEGORY
                            </th>
                            <th className='px-6 min-w-[197px] lg:min-w-[156px] paragraph-overline-small text-neutral-700'>
                                SUB CATEGORY
                            </th>
                            <th className='px-6 min-w-[183px] paragraph-overline-small text-neutral-700'>UPLOADER</th>
                            <th className='px-6 min-w-[227px] paragraph-overline-small text-neutral-700'>
                                UPLOADED ON
                            </th>
                        </tr>
                    </thead>

                    {isLoading ? (
                        <tbody>
                            <TableLoader columns={5} />
                        </tbody>
                    ) : !list.length ? (
                        <BlankComponentTable
                            previewText='No media at this moment'
                            colSpan={5}
                        />
                    ) : (
                        <tbody>
                            {list?.map((el, index) => {
                                const removedSnackCase = el?.image_type?.split('_')?.join(' ');

                                const imageType =
                                    el?.image_type &&
                                    removedSnackCase?.charAt(0)?.toUpperCase() + removedSnackCase?.slice(1);

                                return (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                        key={index}>
                                        <td
                                            className='px-6 sticky left-0 z-[4] cursor-pointer'
                                            onClick={() =>
                                                !isSelectionEnable ? setShowUploadPopup(el.id) : handleSelection(el.id)
                                            }>
                                            <div className='flex flex-row items-center'>
                                                {isSelectionEnable && (
                                                    <span className='mr-2 -ml-2'>
                                                        <CheckBoxWithoutLabels
                                                            id={el.id}
                                                            isChecked={selectedMediaList.includes(el.id)}
                                                        />
                                                    </span>
                                                )}

                                                <img
                                                    src={el.image_url}
                                                    alt=''
                                                    className='rounded mr-3 h-12 w-12'
                                                />

                                                <span className='ml-2'>{el.image_title ?? '--'}</span>
                                            </div>
                                        </td>

                                        <td className='pl-6 sticky left-[326px] z-[11px]'> {imageType ?? '-'}</td>
                                        <td className='pl-6'>
                                            {el.tags.length
                                                ? el.tags.map((tagEl, index) =>
                                                      index === el.tags.length - 1 ? tagEl : `${tagEl}, `
                                                  )
                                                : '-'}
                                        </td>

                                        <td
                                            className='pl-6 text-primary-500 cursor-pointer'
                                            onClick={() => setUserDetail(el.uploader)}>
                                            {el.uploader.name ?? '--'}
                                        </td>

                                        <td className='pl-6'>{el.uploaded_on.replace(/,/g, ', ') ?? '--'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>
        </>
    );
}
