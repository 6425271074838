import React from 'react';
import { Dishes } from '../Components/Catalogue/Components/Card';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';

export default function DishSection(props) {
    const { showDishesPopup, isMobileScreen, handleClickDishes, pizzaDishes } = props;

    const isMobilesScreen = IsMobileScreen();
    const screenWidth = useWindowSize().width;
    const screenHeight = useWindowSize().height;
    return (
        <>
            <div className='w-full ml-6 md:ml-0'>
                <div
                    className='flex flex-row items-center mb-4 md:cursor-pointer'
                    onClick={handleClickDishes}>
                    <span className='mr-1 text-black paragraph-large-medium'>Dishes</span>
                    <span className='paragraph-small-italic text-neutral-500'>(Selected - 00) </span>
                </div>

                {(!showDishesPopup || !isMobileScreen) && (
                    <div>
                        <div className='flex flex-row items-center mb-[18px]'>
                            <CheckBoxWithoutLabels />
                            <span className='paragraph-small-medium ml-2'>Select/deselect all</span>
                        </div>

                        <div
                            className='overflow-auto scrollbar-style -mr-8 md:mr-0 md:overflow-hidden md:h-full'
                            style={{
                                height: isMobilesScreen
                                    ? 'auto'
                                    : screenWidth < 1440
                                      ? screenHeight - 376
                                      : screenWidth < 2100
                                        ? screenHeight - 412
                                        : '616px',
                            }}>
                            {pizzaDishes.map((el, index) => (
                                <div
                                    className={`${index !== pizzaDishes.length - 1 && 'mb-2'} pr-3 md:pr-0`}
                                    key={index}>
                                    <Dishes
                                        boxHeight='h-[96px]'
                                        title={el.title}
                                        variants={el.variants}
                                        image={el.image}
                                        page='menuManagement'
                                        imageSize='h-[74px] w-[74px]'
                                        boxPadding='py-2.5'
                                        isSelectionActive={true}
                                        titleStyle='paragraph-small-medium'
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
