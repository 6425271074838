import React from 'react';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';
import { useSortable } from '@dnd-kit/sortable';
import { ReactComponent as LinearMenuIcon } from '../../../../../../Assets/linear-menu.svg';
import { CSS } from '@dnd-kit/utilities';
import { fornatOrderName } from '../../../../Constants/function';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function TableRows(props) {
    const {
        id,
        isRearrange,
        index,
        display_name,
        type,
        tab,
        is_enabled,
        is_processing,
        setShowTabChangeWarningPopup,
        handleToggleOrderingMode,
    } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const { control } = useFormContext();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    return (
        <>
            <tr
                className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                ref={setNodeRef}
                style={style}
                key={index}
                {...attributes}
                {...listeners}>
                <td className='pl-6 lg:pl-2'>
                    <div className='flex flex-row items-center'>
                        {isRearrange && (
                            <div className='mr-3'>
                                <LinearMenuIcon />
                            </div>
                        )}
                        <span>{display_name[selectedLanguageId] ? display_name[selectedLanguageId] : '--'}</span>
                    </div>
                </td>

                <td className='pl-6 lg:pl-2'>{type ? fornatOrderName(type) : '-'}</td>

                <td className='pl-6 lg:pl-2'>{tab ? fornatOrderName(tab) : '-'}</td>

                <td className='pl-6 lg:pl-2'>
                    {!!is_processing ? (
                        <ToggleSwitch
                            isEnable={false}
                            canChange={false}
                            cursorType={'cursor-not-allowed'}
                        />
                    ) : (
                        <Controller
                            render={({ field }) => (
                                <ToggleSwitch
                                    isEnable={!!is_processing ? false : is_enabled}
                                    canChange={!!is_processing ? false : !isRearrange}
                                    cursorType={(!!is_processing || isRearrange) && 'cursor-not-allowed'}
                                    setIsEnabled={(value) => {
                                        field.onChange(value);
                                        setShowTabChangeWarningPopup(true);
                                        handleToggleOrderingMode(index, value);
                                    }}
                                />
                            )}
                            name={`ordering_modes[${index}].is_enabled`}
                            control={control}
                        />
                    )}
                </td>
            </tr>
        </>
    );
}
