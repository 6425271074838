import React, { useEffect, useState } from 'react';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../Assets/non-veg.svg';
import { ReactComponent as DropDownIcon } from '../../../Assets/chevron-down.svg';
import DetailLoader from '../../ContentLoader/DetailLoader';

const OrderDishes = (props) => {
    const { isUpdated, listView, orderItems, orderDetail } = props;

    return (
        <>
            {/* {!listView && (
                <div className='border-b border-neutral-300 pb-4 mb-4 paragraph-medium-semi-bold flex flex-row justify-between'>
                    <div className='flex flex-row'>
                        <span>
                            {dishPacked == null ? 'Dishes served' : 'Dish packed'} {tableServed}
                        </span>
                    </div>
                    <span>
                        <span className='paragraph-medium-medium text-primary-500'>{dishPacked ?? dishServed}</span> out
                        of 10
                    </span>
                </div>
            )}
            {isUpdated && (
                <div className='mb-4 pb-4 border-b border-neutral-300 text-center'>
                    <span
                        className='paragraph-overline-large text-tertiary-800'
                        style={{ animation: 'fadeIn 2s infinite, fadeOut 2s infinite' }}>
                        ORDERED DISHES UPDATED!
                    </span>
                </div>
            )} */}
            <div className='flex flex-row justify-between mb-4'>
                <span className='text-base leading-4 border-b border-neutral-900'>Ordered dishes</span>
                <span className='text-base leading-4 border-b border-neutral-900'>Quantity</span>
            </div>
        </>
    );
};

export default OrderDishes;

export const ItemsComponent = (props) => {
    const { items, handleClickWithDropDown, showWithItems, isLoading } = props;
    return (
        <div className='-mr-2 pr-2'>
            {isLoading && items?.length > 0 ? (
                <DetailLoader />
            ) : (
                items?.map((el, index) => (
                    <div
                        className='mb-4'
                        key={index}>
                        <div className='flex flex-row justify-between mb-1'>
                            <div className='flex flex-row max-w-[199px]'>
                                <div
                                    className={`whitespace-normal max-w-fit  ${
                                        el.isPrepared ? 'paragraph-medium-strikethrough' : 'paragraph-medium-semi-bold'
                                    } ${el.updatedQuantity && 'text-tertiary-800'}`}>
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            whiteSpace: 'normal',
                                        }}>
                                        {/* <span style={{ marginBottom: '0' }}>
                                            {isLoading ? ( // Assuming `loading` is a state variable
                                                <SingleLoader />
                                            ) : (
                                                el?.item?.translations?.title?.[1] || 'No title available'
                                            )}
                                        </span> */}
                                        {el?.item?.translations?.title[1]}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                marginTop: '3px',
                                                left: '100%',
                                                marginLeft: '0.5rem',
                                            }}>
                                            {el.food_type === 'vegetarian' ? <VegIcon /> : <NonVegIcon />}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='min-w-[70px] text-right'>
                                <span
                                    className={`${
                                        el.isDeleted ? 'paragraph-medium-strikethrough' : 'paragraph-medium-semi-bold'
                                    } ${el.updatedQuantity && 'paragraph-x-small-strikethrough text-tertiary-800'}`}>
                                    {('0' + el.quantity).slice(-2)}
                                </span>
                                {el.updatedQuantity && (
                                    <span className='paragraph-medium-semi-bold text-tertiary-800 ml-1'>
                                        {('0' + el.updatedQuantity).slice(-2)}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='pl-[21px] ml-2 border-l border-neutral-300'>
                            {el?.customizations?.length > 0 && (
                                <div className=''>
                                    <div className='flex flex-row items-center'>
                                        <span
                                            className={`${
                                                el.isPrepared
                                                    ? 'paragraph-small-strikethrough mr-1'
                                                    : 'paragraph-small-medium'
                                            } title-sap relative`}>
                                            With
                                        </span>
                                        {el.isPrepared && (
                                            <div
                                                className='cursor-pointer'
                                                onClick={handleClickWithDropDown}>
                                                <DropDownIcon
                                                    height={20}
                                                    className={`${showWithItems && 'rotate-180'}`}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`flex flex-col paragraph-small-italic text-neutral-500 mb-1 ${
                                            !showWithItems && el.isPrepared && 'hidden'
                                        }`}>
                                        {el?.customizations?.length > 0 &&
                                            el?.customizations?.map((el, index) => (
                                                <div
                                                    className='mt-1'
                                                    key={index}>
                                                    {el.translations.name[1]}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                            {el.addons && (
                                <div className='w-full max-w-[199px]'>
                                    <span className='paragraph-small-medium title-sap relative'>Note</span>
                                    <p className='mt-1 flex flex-col paragraph-small-italic text-neutral-500'>
                                        {el.addons}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};
