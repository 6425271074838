import React, { useCallback, useEffect, useState } from 'react';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import LinkOutletPopup from './Components/LinkOutlet';
import PresetDetails from './Components/PresetDetails';
import { IsMobileScreen } from '../../../../Constants/Constants';
import SelectCategoriesDishesPopup from '../../Popups/SelectCategoriesDishesPopup';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import HeaderCataloguePreset from './Components/HeaderCataloguePreset';
import { fetchCataloguePresets } from '../../functions';
import { CategoryProvider } from '../Catalogue/Context/CategoryContext';

export default function CataloguePreset(props) {
    const { handleClickCreatedBy } = props;
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const [showLinkOutlet, setShowLinkOutlet] = useState(false);
    const [presetDetail, setPresetDetail] = useState();
    const [isLoading, setIsLoading] = useState();
    const [selectedPresetForLink, setSelectedPresetForLink] = useState();
    const [showCancelDelete, setShowCancelDelete] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isDisable, setIsDisable] = useState(true);
    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const handleClickLinkOutlet = (item) => {
        setSelectedPresetForLink(item);
        setShowLinkOutlet(!showLinkOutlet);
    };

    const [showSelectCategoryDishesPopUp, setShowSelectCategoryDishesPopUp] = useState(false);

    const handleClickPreset = () => {
        setShowSelectCategoryDishesPopUp(!showSelectCategoryDishesPopUp);
    };

    const handleFetchCategoryDetails = useCallback(async () => {
        setIsLoading(true);
        const response = await fetchCataloguePresets(selectedRestaurantId);
        setPresetDetail(response?.menus);
        setIsLoading(false);
    }, [selectedRestaurantId]);

    useEffect(() => {
        handleFetchCategoryDetails();
    }, [selectedRestaurantId]);

    useEffect(() => {
        if (checkedItems.length > 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [checkedItems]);

    useEffect(() => {
        setShowCancelDelete(false);
        setShowCheckbox(false);
    }, [isDeleted]);

    const handleClickCancel = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
        setCheckedItems([]);
    };

    const handleBulkSelect = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
    };

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== id));
        }
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <CategoryProvider>
                <HeaderCataloguePreset
                    showCancelDelete={showCancelDelete}
                    isDisable={isDisable}
                    handleClickCancel={handleClickCancel}
                    handleFetchCategoryDetails={handleFetchCategoryDetails}
                    handleBulkSelect={handleBulkSelect}
                />
                <PresetDetails
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    showCheckbox={showCheckbox}
                    selectedRestaurantId={selectedRestaurantId}
                    setPresetDetail={setPresetDetail}
                    presetDetail={presetDetail}
                    handleCheckboxChange={handleCheckboxChange}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    handleClickPreset={handleClickPreset}
                    handleClickLinkOutlet={handleClickLinkOutlet}
                    handleClickCreatedBy={handleClickCreatedBy}
                />

                {!isMobileScreen && !_.isEmpty(presetDetail) && (
                    <div className='mt-4 pb-10'>
                        <PaginationWithNumber
                            lastPage={paginationState.lastPage}
                            selectedPage={paginationState.selectedPage}
                            setSelectedPage={(value) => {
                                handlePagination({ selectedPage: value }, 'selectedPage');
                            }}
                            setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                            selectedResultPerpage={paginationState.resultPerPage}
                            isLoading={isLoading}
                        />
                    </div>
                )}

                {showLinkOutlet && (
                    <LinkOutletPopup
                        selectedRestaurantId={selectedRestaurantId}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        selectedPresetForLink={selectedPresetForLink}
                        headerBottomLine='Link the menu with the desired outlets'
                        page='cataloguePreset'
                        handleClickClose={handleClickLinkOutlet}
                    />
                )}

                {showSelectCategoryDishesPopUp && (
                    <SelectCategoriesDishesPopup
                        page='Catalogue Preset'
                        handleClickClose={handleClickPreset}
                    />
                )}
            </CategoryProvider>
        </>
    );
}
