import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../../Assets/chevron-down.svg';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';

export default function ListViewOfferLink(props) {
    const { displayName, state, city, address, handleChangeLinking, isEnable, id } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md'>
            <div
                className='flex flex-row items-center justify-between cursor-pointer'
                onClick={handleClickShowDetails}>
                <div className='flex flex-row items-center'>
                    <div
                        className='mr-4'
                        onClick={(e) => e.stopPropagation()}>
                        <ToggleSwitch
                            isEnable={isEnable}
                            canChange
                            setIsEnabled={(doLink) => handleChangeLinking(doLink, id)}
                        />
                    </div>
                    <div className='flex flex-row justify-center gap-1'>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1 flex'>OUTLET NAME:</h3>
                        <span className='paragraph-small-regular flex'>{displayName}</span>
                    </div>
                </div>
                <div className='flex flex-row items-center'>
                    <div className={`${isShowDetails && 'rotate-180'}`}>
                        <DownArrow />
                    </div>
                </div>
            </div>

            {isShowDetails && (
                <div className='mt-[5px] ml-14'>
                    <div className='mb-[7px]'>
                        <span className='paragraph-overline-small text-neutral-700'>STATE:</span>
                        <span className='paragraph-small-regular ml-1'>{state}</span>
                    </div>
                    <div className='mb-[7px]'>
                        <span className='paragraph-overline-small text-neutral-700'>CITY:</span>
                        <span className='paragraph-small-regular ml-1'>{city}</span>
                    </div>
                    <div className='mb-[7px] flex'>
                        <span className='paragraph-overline-small text-neutral-700'>ADDRESS:</span>
                        <span className='paragraph-small-regular ml-1'>{address}</span>
                    </div>
                </div>
            )}
        </div>
    );
}
