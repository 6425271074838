import React, { useRef, useState } from 'react';
import { ReactComponent as Moped } from '../../../Assets/moped.svg';
import { LargeDestructiveButton, SmallTertiaryButton } from '../../Buttons/Button';
import { riderCancel } from '../../../Screen/Order/Helper/functions';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import { IsMobileScreen } from '../../../Constants/Constants';
import { capitalizeFirstLetter } from '../../../Helper/HelperFunctions';
import ViewHelpServicesPopup from '../../../Screen/Order/OrderTabPage/LiveOrder/Popup/ViewHelpServicesPopup/ViewHelpServicesPopup';

export default function AssignedOrder(props) {
    const { setCancelRiders, setShowPopup, handleClickClose, fetchData, orderDetail } = props;
    // const pidgeService = orderDetail?.orderDetail?.pidge_order;
    let deliveryService = null;
    // const orderDetails = orderDetail?.orderDetail;
    const orderId = orderDetail?.order_id;

    let riderDetails = {};
    if (orderDetail?.pidge_order) {
        riderDetails = orderDetail?.pidge_order;
        deliveryService = 'pidge';
    } else if (orderDetail?.porter_order) {
        riderDetails = orderDetail?.porter_order;
        deliveryService = 'porter';
    } else if (orderDetail?.shadowfax_order) {
        riderDetails = orderDetail?.shadowfax_order;
        deliveryService = 'shadowfax';
    } else if (orderDetail?.wefast_order) {
        riderDetails = orderDetail?.wefast_order;
        deliveryService = 'wefast';
    } else if (orderDetail?.dunzo_order) {
        riderDetails = orderDetail?.dunzo_order;
        deliveryService = 'dunzo';
    } else if (orderDetail?.elt_order) {
        riderDetails = orderDetail?.elt_order;
    } else {
        riderDetails = { ...orderDetail?.rider, ...orderDetail?.rider_order, order_id: orderDetail.order_id };
    }
    const isMobileScreen = IsMobileScreen();
    const viewOptionRef = useRef(null);
    const [showViewDropdown, setShowViewDropdown] = useState(false);
    const [ViewHelpServices, setViewHelpServices] = useState(false);
    useOutsideClick(viewOptionRef, () => setShowViewDropdown(false));
    const handleClickCancleRider = async () => {
        // setCancelRiders(true);
        try {
            const response = await riderCancel(deliveryService, orderId, handleClickClose);

            if (response.success) {
                await fetchData();
            }
            // window.location.reload();
        } catch (error) {
            console.error('Failed to assign rider:', error);
        }
    };
    const viewControllerDropdown = [
        { item: 'Cancel Rider', onClick: () => handleClickCancleRider() },
        { item: 'Help', onClick: () => setViewHelpServices(true) },
    ];

    const renderViewDropdown = () => (
        // !isMobileScreen && (
        <div className='relative'>
            <div
                className='flex flex-col items-center justify-center gap-1 cursor-pointer p-2 -m-1'
                onClick={() => setShowViewDropdown((prev) => !prev)}>
                {[...Array(3)].map((_, i) => (
                    <div
                        key={i}
                        className='bg-black h-[3px] w-[3px] rounded-full'
                    />
                ))}
            </div>
            {showViewDropdown && (
                <div
                    className='border border-neutral-300 rounded-xl px-4 w-[152px] absolute top-6 bg-white right-0'
                    ref={viewOptionRef}>
                    {viewControllerDropdown.map((el, index) => (
                        <div
                            key={index}
                            className='py-1.5 cursor-pointer paragraph-medium-regular'
                            onClick={() => {
                                el.onClick();
                                setShowViewDropdown(false);
                            }}>
                            <span>{el.item}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
    // );

    const helpServices = [
        {
            name: 'Customer Support',
            email: 'support@example.com',
            phone: '+1234567890',
            address: '123 Support St, Support City, SC 12345',
        },
        {
            name: 'Technical Support',
            email: 'techsupport@example.com',
            phone: '+0987654321',
            address: '456 Tech St, Tech City, TC 67890',
        },
    ];
    const hasRiderAssigned = () => {
        return (
            orderDetail?.rider ||
            orderDetail?.dunzo_order?.rider_name ||
            orderDetail?.wefast_order?.rider_name ||
            orderDetail?.scout_runner_order?.rider_name ||
            orderDetail?.shadowfax_order?.rider_name ||
            orderDetail?.porter_order?.rider_name ||
            orderDetail?.pidge_order?.rider_name ||
            orderDetail?.flash_order?.rider_name ||
            orderDetail?.sfx_store_based_order
        );
    };

    const renderRiderInfo = () => {
        if (!hasRiderAssigned()) {
            return (
                <p className='paragraph-medium-regular pb-[0.75rem] ml-2'>
                    Waiting for {deliveryService ? capitalizeFirstLetter(deliveryService) : 'rider'} to assign rider..
                </p>
            );
        } else {
            return (
                <p className='paragraph-medium-regular pb-[0.75rem] ml-2'>
                    {riderDetails?.first_name} {riderDetails?.middle_name} {riderDetails?.last_name}
                </p>
            );
        }
    };
    if (riderDetails) {
        return (
            <div className='px-2 mt-2 mb-3 bg-[#FAFAFA] rounded-xl '>
                <div className='flex flex-row justify-between text-2xl border-b border-neutral-300 pb-[0.75rem] pt-[0.75rem] mx-2'>
                    <div className='flex flex-row'>
                        <Moped
                            height={28}
                            width={28}
                        />{' '}
                        <span className='mx-3 paragraph-large-medium'>
                            {deliveryService ? capitalizeFirstLetter(deliveryService) + ' Rider' : 'Rider'}
                        </span>
                    </div>
                    <div className='flex flex-row'>{renderViewDropdown()}</div>
                </div>
                {orderDetail?.pidge_order && (
                    <p className='text-[#706E7E] paragraph-medium-medium'>
                        Rider service name :{' '}
                        <span className='paragraph-medium-regular ml-2'>{riderDetails?.network_name}</span>
                    </p>
                )}
                <div className='flex flex-row justify-between px-2 mt-[0.75rem] mb-[0.75rem]'>
                    <div className="'flex flex-col">
                        <p className='text-[#706E7E] paragraph-medium-medium'>
                            Order Id :<span className='paragraph-medium-regular ml-2'>{riderDetails?.order_id}</span>
                        </p>
                        <p className='text-[#706E7E] paragraph-medium-medium'>
                            Estimated amount :
                            <span className='paragraph-medium-regular ml-2'>
                                {riderDetails?.estimated_price || riderDetails?.rider_restaurant_cod_settlement
                                    ? `₹${riderDetails?.estimated_price || riderDetails?.rider_restaurant_cod_settlement}`
                                    : 'N/A'}
                            </span>
                        </p>
                    </div>
                    <div className='flex flex-col'>
                        <p className='text-[#706E7E] paragraph-medium-medium'>
                            Distance :
                            <span className='paragraph-medium-regular ml-2'>
                                {riderDetails?.distance ? `${riderDetails.distance}` : 'N/A'}
                            </span>
                        </p>
                        <p className='text-[#706E7E] paragraph-medium-medium'>
                            Mobile No :
                            <span className='paragraph-medium-regular ml-2'>
                                {riderDetails?.mobile_number ? riderDetails.mobile_number : 'N/A'}
                            </span>
                        </p>
                    </div>
                </div>

                {renderRiderInfo()}

                {/* <div className='w-full p-[1rem] flex flex-row justify-end'>
                    <div onClick={handleClickCancleRider}>
                        <LargeDestructiveButton
                            label='Cancel Rider'
                            isDefault={false}
                        />
                    </div>
                </div> */}
                {ViewHelpServices && (
                    <ViewHelpServicesPopup
                        handleClickClose={() => setViewHelpServices(false)}
                        backToPage='Order Details'
                        services={helpServices}
                    />
                )}
            </div>
        );
    }
    return null;
}
