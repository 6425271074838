import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import SearchDropDown from '../../../../Components/SearchDropDown';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { getRestaurantAddonGroups } from '../../../../functions';

export default function AddonSelectionComp(props) {
    const { selectionVariantId } = props;
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const { watch, setValue } = useFormContext();

    const [searchedQuery, setSearchedQuery] = useState('');

    const [pageState, setPageState] = useState({ page: 1, hasMorePage: true });

    const isMobileScreen = IsMobileScreen();

    const setAddonList = async (searchedQuery, page = 1) => {
        try {
            const response = await getRestaurantAddonGroups(selectedRestaurantId, searchedQuery, page);

            setPageState((prevState) => ({
                ...prevState,
                hasMorePage: response.restaurant_addon_groups.has_more_pages,
            }));

            return response?.restaurant_addon_groups?.data?.map((el) => ({ label: el.internal_name, value: el }));
        } catch (error) {
            console.log(error);
        }
    };

    const handleFetchList = useCallback(
        _.debounce(async (searchedQuery) => {
            const addonList = await setAddonList(searchedQuery, pageState.page);

            setValue(`availableAddon.${selectionVariantId}`, addonList);
        }, 1000)
    );

    const handleSearch = (searchedQuery) => {
        setSearchedQuery(searchedQuery);
        handleFetchList(searchedQuery);
    };
    const handleSelectItem = (isSelected, item) => {
        let availableAddon = watch(`availableAddon.${selectionVariantId}`);

        availableAddon = availableAddon?.map((el) => {
            if (el?.value?.addon_group_id === item?.value?.addon_group_id) {
                return { ...el, isSelected };
            }
            return el;
        });

        setValue(`availableAddon.${selectionVariantId}`, availableAddon);

        const filteredAddons = availableAddon.filter((el) => !!el.isSelected);

        setValue(`${selectionVariantId}`, filteredAddons);
    };

    const fetchMoreData = async () => {
        if (!pageState.hasMorePage) return;

        const nextPage = pageState.page + 1;

        const addonList = await setAddonList(searchedQuery, nextPage);

        setValue(`availableAddon.${selectionVariantId}`, [
            ...watch(`availableAddon.${selectionVariantId}`),
            ...addonList,
        ]);

        setPageState((prevState) => ({ ...prevState, page: nextPage }));
    };

    const fetchInitialAddonList = async () => {
        const addonList = await setAddonList();

        !watch(`availableAddon.${selectionVariantId}`) && setValue(`availableAddon.${selectionVariantId}`, addonList);
    };

    useEffect(() => {
        fetchInitialAddonList();
    }, []);

    return (
        <>
            <span className='paragraph-medium-italic text-neutral-500'>(Select add-on groups)</span>

            <div className='mb-4 max-w-[312px] relative'>
                <SearchDropDown
                    fixedHeight='h-[300px]'
                    searchInputPlaceholder='Search add-on by typing...'
                    menuItems={watch(`availableAddon.${selectionVariantId}`)}
                    openFromBottom={isMobileScreen ?? true}
                    handleSearch={handleSearch}
                    handleSelectItem={handleSelectItem}
                    fetchMoreData={fetchMoreData}
                    placeholder='Select add-on groups'
                />
            </div>
        </>
    );
}
