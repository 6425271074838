import React, { useEffect, useState } from 'react';
import Table from './Table';
import { ReactComponent as CreditIcon } from '../../../Assets/credits.svg';
import { ReactComponent as RechargeIcon } from '../../../Assets/recharge.svg';
import { CreditInformationCard, ReferenceCard } from './Card';
import { IsIpadScreen, IsMobileScreen } from '../../../Constants/Constants';
import CalenderExportData from './CalenderExportData';
import { format, startOfMonth } from 'date-fns';
import PaginationWithNumber from '../../../Components/Pagination/PaginationWithNumber';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import CardContentLoader from '../../../Components/ContentLoader/CardContentLoader';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';

export default function InfrastructureCredit(props) {
    const {
        fetchTransactionList,
        creditType,
        isInfrastructureListLoading,
        creditList,
        setCreditList,
        isCardDetailLoading,
        setIsCardDetailLoading,
        selectedDates,
        setSelectedDates,
        creditAndRecharge,
        setCreditAndRecharge,
        fetchInfrastructureCardDetails,
        cardLable,
    } = props;
    const today = new Date();

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const isMobileScreen = IsMobileScreen();

    const isIpadScreen = IsIpadScreen();

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const infrastructureCardContent = [
        {
            title: 'Available infrastructure credits',
            number: creditAndRecharge.totalInfrastructureCredit,
            description: 'These credits are not real-time',
            icon: (
                <CreditIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Number of recharges',
            number: creditAndRecharge.numberOfRecharges,
            description: `Total number of recharge done for ${cardLable}`,
            icon: (
                <RechargeIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
    ];

    useEffect(() => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        const filters = {
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
        };
        fetchInfrastructureCardDetails({ params: { filters } });

        // eslint-disable-next-line
    }, [selectedDates, selectedOutletId]);

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        return {
            filters: {
                start_date: formatDate(from_date),
                end_date: formatDate(to_date),
            },
        };
    };

    const setData = async (params) => {
        try {
            const defaultParamsData = defaultParams();

            const response = await fetchTransactionList({
                ...defaultParamsData,
                per_page: params.per_page ?? paginationState.resultPerPage,
                page: params.page ?? paginationState?.selectedPage,
            });

            setCreditList(response.transactions.data);
            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.transactions.last_page,
                    selectedPage: response.transactions.current_page,
                };
            });
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        const params = {
            page: paginationState.selectedPage,
            per_page: paginationState.resultPerPage,
        };
        setData(params);
    }, [selectedDates, paginationState.selectedPage, paginationState.resultPerPage, selectedOutletId]);

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    return (
        <>
            <CalenderExportData
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
                creditType={creditType}
            />

            <div className='mb-4 lg:mb-2'>
                {infrastructureCardContent.map((el, index) => (
                    <div
                        className={`w-full max-w-[303px] inline-block mr-5 align-top lg:w-1/2 lg:max-w-full lg:odd:pr-1 lg:even:pl-1 lg:mr-0 md:w-full md:even:pl-0 md:odd:pr-0 md:my-1 ${
                            isCardDetailLoading ? '-mx-[11px] mb-6 lg:-mx-[1px] lg:mb-3' : ''
                        }`}
                        key={index}>
                        {isCardDetailLoading ? <CardContentLoader /> : <CreditInformationCard {...el} />}
                    </div>
                ))}
            </div>

            {!isIpadScreen ? (
                isInfrastructureListLoading ? (
                    <table>
                        <tbody>
                            <TableLoader
                                hasSwitch={false}
                                columns={6}
                            />
                        </tbody>
                    </table>
                ) : (
                    <Table tableContent={creditList} />
                )
            ) : (
                <div>
                    {creditList && creditList.length > 0 ? (
                        creditList.map((el, index) => (
                            <div
                                className='w-1/2 inline-block odd:pr-1 even:pl-1 lg:my-2 md:w-full md:even:pl-0 md:odd:pr-0 md:my-1'
                                key={index}>
                                <ReferenceCard
                                    referenceId={el?.reference_id ?? '---'}
                                    type={el?.type ?? '---'}
                                    service={el?.third_party_api_name ?? '---'}
                                    amount={el?.amount ?? '---'}
                                    rechargeDate={el?.date ?? '---'}
                                    status={el?.status ?? '---'}
                                />
                            </div>
                        ))
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}

            {!isMobileScreen && (
                <div className='pt-4 pb-10'>
                    <PaginationWithNumber
                        lastPage={paginationState.lastPage}
                        selectedPage={paginationState.selectedPage}
                        setSelectedPage={(value) => {
                            handlePagination({ selectedPage: value }, 'selectedPage');
                        }}
                        setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                        selectedResultPerpage={paginationState.resultPerPage}
                        isLoading={isInfrastructureListLoading}
                    />
                </div>
            )}
        </>
    );
}
