import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { ReactComponent as FilterIcon } from '../../../../../Assets/filter.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../../Assets/chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import LinkOutletTable from './LinkOutletTable';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import { editToggleOutlet } from '../../../functions';
import AlreadyLinkPopup from './AlreadyLinkPopup';
import { useSelector } from 'react-redux';

export default function LinkOutletPopup(props) {
    const { handleClickClose, headerBottomLine, selectedPresetForLink, selectedRestaurantId } = props;
    const [outletsToUpdate, setOutletsToUpdate] = useState({
        linking_outlets_ids: [],
        unlinking_outlets_ids: [],
    });
    const outletList = useSelector((state) => state.outlet.outletList);
    const [apiError, setApiError] = useState(null);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const isMobileScreen = IsMobileScreen();
    const handleSaveChanges = async () => {
        try {
            if (outletsToUpdate.linking_outlets_ids.length > 0 || outletsToUpdate.unlinking_outlets_ids.length > 0) {
                await editToggleOutlet(
                    selectedRestaurantId,
                    selectedPresetForLink.id, // Or .menu_id if that's the correct field
                    outletsToUpdate.linking_outlets_ids,
                    outletsToUpdate.unlinking_outlets_ids
                );
                setOutletsToUpdate({ linking_outlets_ids: [], unlinking_outlets_ids: [] });
            }
        } catch (error) {
            console.error('Error updating outlets:', error);
            if (error.response && error?.response?.data) {
                setApiError(error?.response?.data?.errors);
                setShowErrorPopup(true);
            } else {
                setApiError({ message: 'An unexpected error occurred.' });
                setShowErrorPopup(true);
            }
        }
    };
    const closeErrorPopup = () => {
        setShowErrorPopup(false);
        setApiError(null);
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:absolute p-4 md:py-0'>
                <div className='max-w-[997px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-4 md:pt-4 m-auto md:max-w-full lg:max-w-[710px] md:absolute md:top-0 md:pb-24'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium ml-1'>Back to catalogue preset</span>
                        </div>
                    )}
                    {!isMobileScreen && (
                        <div className='flex flex-row justify-between items-center mb-2 pb-4'>
                            <div>
                                <span className='paragraph-large-medium'>Link outlets</span>
                                <div className='flex flex-row items-center'>
                                    <span className='font-normal italic text-base leading-6 text-neutral-500'>
                                        {headerBottomLine}
                                    </span>
                                    <div className='w-1 h-1 rounded-full mx-3 bg-neutral-500' />
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        Outlet selected - 04
                                    </span>
                                </div>
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={() => handleClickClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row justify-between mb-6 md:block md:mb-4'>
                        <div className='max-w-[375px] w-full lg:max-w-[298px] md:max-w-full md:mb-4'>
                            <DefaultInputField
                                placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                placeholder='Search outlet'
                            />
                        </div>
                        <div className='flex flex-row items-center md:justify-between'>
                            <LargePrimaryButton
                                label='Filters'
                                leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                                leftIconClick={<FilterIcon fill='#C4BEED' />}
                            />
                        </div>
                    </div>

                    <LinkOutletTable
                        outletList={outletList}
                        setOutletsToUpdate={setOutletsToUpdate}
                        selectedPresetForLink={selectedPresetForLink}
                    />

                    {showErrorPopup && apiError && (
                        <AlreadyLinkPopup
                            errorMessage={apiError}
                            onClose={closeErrorPopup}
                            outletList={outletList} // Pass the outletList
                        />
                    )}

                    <div className='flex justify-end mt-12 md:fixed md:bottom-0 md:justify-center md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4 md:px-4'>
                        <div className='flex flex-row'>
                            <div
                                className='mr-2 md:w-full'
                                onClick={() => handleClickClose()}>
                                <LargeDestructiveButton
                                    label='Discard changes'
                                    fontsSize='sm:text-[15px]'
                                />
                            </div>
                            <div
                                className='ml-2 md:w-full'
                                onClick={handleSaveChanges}>
                                <LargePrimaryButton
                                    label='Save changes'
                                    fontsSize='sm:text-[15px]'
                                    isClicked={showErrorPopup ? false : {}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
