import React, { useEffect, useState } from 'react';
import CategorySection from './Sections/CategorySection';
import DishesSection from './Sections/DishesSection';
import DetailsSection from './Sections/DetailsSection';
import ControllerButton from './Components/ControllerButton';
import DishMapCopyMovePopup from './Popup/DishMapCopyMovePopup/DishMapCopyMovePopup';
import ReArrangeCategoryDishFlyout from './Sections/Flyout/ReArrangeCategoryDishFlyout';
import { CategoryProvider } from './Context/CategoryContext';
import CreatePresetPopup from '../../Popups/CreatePresetPopup';
import SelectCategoriesDishesPopup from '../../Popups/SelectCategoriesDishesPopup';

export default function Catalogue() {
    const [pageState, setPageState] = useState({ edit: false, reArrange: false, selection: false });

    const [showCreatePreset, setShowCreatePreset] = useState(false);

    const [selectedCategoryDish, setSelectedCategoryDish] = useState({ category: '', dish: '' });

    const [allCategoryDish, setAllCategoryDish] = useState([]);

    const [dishOperationState, setDishOperationState] = useState(null);

    const [isOperationLoading, setIsOperationLoading] = useState(false);

    const [selectedDishId, setSelectedDishId] = useState([]);

    const [selectedCategoryId, setSelectedCategoryId] = useState([]);

    const [clickedCategory, setClickedCategory] = useState(null);

    const [presetName, setPresetName] = useState('');

    const [showCreatePresetPageSecound, setShowCreatePresetPageSecound] = useState(false);

    const handleCloseCreatePreset = () => {
        setShowCreatePreset(false);
        setShowCreatePresetPageSecound(false);
    };

    const handleProceedCreatePreset = () => {
        // need to render the 2nd page of the popup
        setShowCreatePresetPageSecound(true);
    };

    return (
        <>
            <CategoryProvider>
                <ControllerButton
                    pageState={pageState}
                    setPageState={setPageState}
                    selectedDishId={selectedDishId}
                    selectedCategoryId={selectedCategoryId}
                    setShowCreatePreset={setShowCreatePreset}
                />
                <div className='flex flex-row items-start xl:block w-full'>
                    <div className='flex md:block w-full'>
                        <div className='pr-8 mr-8 border-r border-neutral-300 lg:pr-4 lg:ml-4 md:border-0 md:ml-0 md:pr-0 md:mr-0 md:mb-4 max-w-[450px] w-full'>
                            <CategorySection
                                pageState={pageState}
                                setSelectedCategoryDish={setSelectedCategoryDish}
                                selectedCategoryDish={selectedCategoryDish}
                                allCategoryDish={allCategoryDish}
                                setAllCategoryDish={setAllCategoryDish}
                                isOperationLoading={isOperationLoading}
                                setIsOperationLoading={setIsOperationLoading}
                                setSelectedCategoryId={setSelectedCategoryId}
                                selectedCategoryId={selectedCategoryId}
                                setClickedCategory={setClickedCategory}
                            />
                        </div>

                        <div className='max-w-[450px] w-full xl:max-w-full mr-8 pr-8 pageContentSmall:mr-4 pageContentSmall:pr-4 border-r border-neutral-300 xl:mr-0 xl:pr-0 xl:border-none'>
                            <DishesSection
                                pageState={pageState}
                                setSelectedCategoryDish={setSelectedCategoryDish}
                                selectedCategoryDish={selectedCategoryDish}
                                allCategoryDish={allCategoryDish}
                                setAllCategoryDish={setAllCategoryDish}
                                isOperationLoading={isOperationLoading}
                                setIsOperationLoading={setIsOperationLoading}
                                selectedDishId={selectedDishId}
                                setSelectedDishId={setSelectedDishId}
                            />
                        </div>
                    </div>

                    <div className='max-w-[430px] w-full xl:max-w-full xl:border-t xl:border-neutral-300 xl:mt-8 xl:pt-6 md:mt-5 md:pt-5'>
                        <DetailsSection
                            selectedCategoryDish={selectedCategoryDish}
                            setDishOperationState={setDishOperationState}
                            clickedCategory={clickedCategory}
                        />
                    </div>
                </div>
                {dishOperationState && (
                    <DishMapCopyMovePopup
                        selectedCategoryDish={selectedCategoryDish}
                        dishOperationState={dishOperationState}
                        setIsOperationLoading={setIsOperationLoading}
                        handleClickClose={() => setDishOperationState(null)}
                    />
                )}
                {pageState.reArrange && (
                    <ReArrangeCategoryDishFlyout
                        selectedCategoryDish={selectedCategoryDish}
                        handleClickClose={() => setPageState((prevState) => ({ ...prevState, reArrange: false }))}
                        setIsOperationLoading={setIsOperationLoading}
                    />
                )}
                {showCreatePreset && (
                    <CreatePresetPopup
                        handleClickProceed={handleProceedCreatePreset}
                        handleClickClose={handleCloseCreatePreset}
                        presetName={presetName}
                        setPresetName={setPresetName}
                    />
                )}

                {showCreatePresetPageSecound && (
                    <SelectCategoriesDishesPopup
                        page='Catalogue'
                        handleClickClose={handleCloseCreatePreset}
                        presetName={presetName}
                        setPresetName={setPresetName}
                    />
                )}
            </CategoryProvider>
        </>
    );
}
