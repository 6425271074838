import React, { useEffect, useState } from 'react';
import Card from './Card';
import SliderDashboard from 'react-slick';
import { ReactComponent as CreditsIcon } from '../../../Assets/credits.svg';
import { ReactComponent as NetSalesIcon } from '../../../Assets/net-sales.svg';
import { ReactComponent as NoOfOrderIcon } from '../../../Assets/no-of-orders.svg';
import { ReactComponent as OnlineOrdersIcon } from '../../../Assets/online-orders.svg';
import { ReactComponent as DiscountIcon } from '../../../Assets/discount.svg';
import { ReactComponent as TaxesIcon } from '../../../Assets/taxes.svg';
import { ReactComponent as CashCollectionIcon } from '../../../Assets/cash-collection.svg';
import { CardDescription } from './CardComponent';
import { IsMobileScreen } from '../../../Constants/Constants';
import APIV5 from '../../../api/axios/APIV5';
import { format } from 'date-fns';

const fetchData = async (url, selectedRestaurantId, outletId, defaultParams, setData) => {
    if (!outletId || !selectedRestaurantId) {
        console.warn('Outlet ID or Restaurant ID is missing. Skipping API call.');
        return; // Exit early if either value is missing
    }

    const defaultParamsData = defaultParams();
    try {
        const response = await APIV5.get(url, {
            params: {
                ...defaultParamsData,
                outlet_id: [outletId],
            },
        });
        setData(response);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default function OverviewCards(props) {
    const {
        settingsDashboardSlider,
        numberOfOutlet,
        discountAmount,
        setShowPopupDetails,
        isRestaurantSelected,
        selectedDates,
        outletId,
        selectedOutlet,
        selectedRestaurantId,
    } = props;

    const displayOutlet = isRestaurantSelected ? numberOfOutlet : selectedOutlet?.displayName;
    const [totalSaleData, setTotoalSaleData] = useState([]);
    const [netSaleData, setNetSaleData] = useState([]);
    const [ordersData, setOrdersData] = useState([]);
    const [cashCollectionData, setCashCollectionData] = useState([]);
    const [onlineSales, setOnlineSalesData] = useState([]);
    const [tax, setTaxData] = useState([]);
    const [totalDiscount, setTotalDiscount] = useState([]);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');
    const defaultParams = () => {
        const [start_date, end_date] = selectedDates.sort((a, b) => a - b);
        return {
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
        };
    };

    const fetchOverviewData = () => {
        fetchData(
            `restaurants/${selectedRestaurantId}/total-sales`,
            selectedRestaurantId,
            outletId,
            defaultParams,
            setTotoalSaleData
        );
        fetchData(
            `restaurants/${selectedRestaurantId}/net-sales`,
            selectedRestaurantId,
            outletId,
            defaultParams,
            setNetSaleData
        );
        fetchData(
            `restaurants/${selectedRestaurantId}/order-counts`,
            selectedRestaurantId,
            outletId,
            defaultParams,
            setOrdersData
        );
        fetchData(
            `restaurants/${selectedRestaurantId}/cash-collection`,
            selectedRestaurantId,
            outletId,
            defaultParams,
            setCashCollectionData
        );
        fetchData(
            `restaurants/${selectedRestaurantId}/online-sales`,
            selectedRestaurantId,
            outletId,
            defaultParams,
            setOnlineSalesData
        );
        fetchData(
            `restaurants/${selectedRestaurantId}/total-tax`,
            selectedRestaurantId,
            outletId,
            defaultParams,
            setTaxData
        );
        fetchData(
            `restaurants/${selectedRestaurantId}/total-discounts`,
            selectedRestaurantId,
            outletId,
            defaultParams,
            setTotalDiscount
        );
    };
    useEffect(() => {
        fetchOverviewData();
    }, [selectedDates, outletId, selectedRestaurantId]);

    const cardContent = [
        {
            title: 'Total sales',
            number: totalSaleData?.total_sales,
            percentage: totalSaleData?.percentage_change,
            positive: totalSaleData?.success,
            description: (
                <CardDescription
                    focusContent={totalSaleData?.sales_difference}
                    description='You made an extra total sales of :focusContent: this week'
                    focusContentColor={totalSaleData?.sales_difference < 0 ? 'text-red-600' : 'text-tertiary-800'}
                />
            ),
            information: `Total sales of ${displayOutlet} outlets`,
            icon: (
                <CreditsIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Total sales',
                description: 'Total sales defines the overall collection restaurant made',
                popup: 'totalSales',
            },
        },
        {
            title: 'Net sales',
            number: netSaleData?.net_sales,
            percentage: netSaleData?.percentage_change,
            description: (
                <CardDescription
                    focusContent={netSaleData?.sales_difference}
                    description='You made an extra net sales of :focusContent: this week'
                    focusContentColor={netSaleData?.sales_difference < 0 ? 'text-red-600' : 'text-tertiary-800'}
                />
            ),
            information: `Net sales of ${displayOutlet} outlets`,
            positive: netSaleData?.success,
            icon: (
                <NetSalesIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Net sales',
                description: 'Net sales defines the overall collection restaurant made',
                popup: 'netSales',
            },
        },
        {
            title: 'No. of orders',
            number: ordersData?.no_of_orders,
            percentage: ordersData?.percentage_change,
            description: (
                <CardDescription
                    focusContent={ordersData?.order_difference}
                    description='You made :focusContent: orders less than last week'
                    focusContentColor={ordersData?.order_difference < 0 ? 'text-red-600' : 'text-tertiary-800'}
                />
            ),
            information: 'No. of invoices generated',
            positive: ordersData?.success,
            icon: (
                <NoOfOrderIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Total number of orders',
                description: 'Total number of orders for all ordering modes',
                popup: 'noOfOrders',
            },
        },

        {
            title: 'Cash collection',
            number: cashCollectionData?.cash_collections,
            percentage: cashCollectionData?.percentage_change,
            description: (
                <CardDescription
                    focusContent={cashCollectionData?.offline_sales_difference_in_percentage}
                    description='You made :focusContent: of offline sales collected via cash'
                    focusContentColor={
                        cashCollectionData?.offline_sales_difference_in_percentage < 0
                            ? 'text-red-600'
                            : 'text-tertiary-800'
                    }
                />
            ),
            information: `Cash collection of ${displayOutlet} outlets`,
            positive: cashCollectionData?.success,
            icon: (
                <CashCollectionIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            // ...(isRestaurantSelected
            //     ? {
            popupDetails: {
                title: 'Taxes details',
                description: 'Taxes details for different outlets',
                popup: 'cashCollection',
            },
            //   }
            // : {}),
        },
        {
            title: 'Online sales',
            number: onlineSales?.online_net_total ? onlineSales?.online_net_total : 0,
            percentage: onlineSales?.percentage_change,
            description: (
                <CardDescription
                    focusContent={onlineSales?.online_sales_in_percentage}
                    description='You made :focusContent: of sales generated from online'
                    focusContentColor={
                        onlineSales?.online_sales_in_percentage < 0 ? 'text-red-600' : 'text-tertiary-800'
                    }
                />
            ),
            information: `Online sale of ${displayOutlet} outlets`,
            positive: onlineSales?.success,
            icon: (
                <OnlineOrdersIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Online sales',
                description: 'Online sales defines the overall online sale for ordering modes',
                popup: 'onlineSales',
            },
        },
        {
            title: 'Taxes',
            number: tax?.taxes ? tax?.taxes : 0,
            description: (
                <CardDescription
                    focusContent={tax?.taxes ? tax?.taxes : 0}
                    description='You paid :focusContent: taxes recorded on POS'
                    focusContentColor='text-primary-500'
                />
            ),
            information: `Taxes record of ${displayOutlet} outlets`,
            positive: tax?.success,
            icon: (
                <TaxesIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Taxes details',
                description: 'Taxes details for different ordering modes',
                popup: 'taxes',
            },
        },
        {
            title: 'Discounts',
            number: totalDiscount?.discounts ? totalDiscount?.discounts : 0,
            description: (
                <CardDescription
                    focusContent={totalDiscount?.discount_percentage ? totalDiscount?.discount_percentage : 0}
                    description='You gave a discount of :focusContent: amount'
                    focusContentColor={totalDiscount?.discount_percentage < 0 ? 'text-red-600' : 'text-tertiary-800'}
                />
            ),
            information: `${discountAmount} of my amount`,
            positive: totalDiscount?.success,
            icon: (
                <DiscountIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Discounts details',
                description: 'Discounts details for different ordering modes',
                popup: 'discount',
            },
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='px-8 lg:px-4 md:px-0'>
                <div className='paragraph-large-medium text-black md:px-4 pb-2 md:pb-2'>Overview</div>
                {!isMobileScreen ? (
                    <div className='flex flex-wrap -mx-2.5'>
                        {cardContent.map((el, index) => (
                            <div
                                className='inline-block mx-2.5 my-2 max-w-[303px] w-full lg:w-1/2 lg:max-w-full lg:mx-0 lg:even:pl-1 lg:odd:pr-1 lg:my-1'
                                key={index}>
                                <Card
                                    {...el}
                                    showDetailsPopup={() => {
                                        el.popupDetails && setShowPopupDetails(el.popupDetails);
                                    }}
                                    showAbout={!!el.popupDetails}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <div>
                            <SliderDashboard {...settingsDashboardSlider}>
                                {cardContent.slice(0, 4).map((el, index) => (
                                    <div
                                        className='inline-block mx-2.5 my-2 md:mx-0 md:my-0 pb-1.5'
                                        key={index}>
                                        <Card
                                            {...el}
                                            showDetailsPopup={() => {
                                                el.popupDetails && setShowPopupDetails(el.popupDetails);
                                            }}
                                            showAbout={!!el.popupDetails}
                                        />
                                    </div>
                                ))}
                            </SliderDashboard>
                        </div>

                        <div className='mt-1.5 md:mt-0'>
                            <SliderDashboard {...settingsDashboardSlider}>
                                {cardContent.slice(4, 7).map((el, index) => (
                                    <div
                                        className='inline-block mx-2.5 my-2 md:mx-0 md:my-0 pb-1.5'
                                        key={index}>
                                        <Card
                                            {...el}
                                            showDetailsPopup={() => {
                                                el.popupDetails && setShowPopupDetails(el.popupDetails);
                                            }}
                                            showAbout={!!el.popupDetails}
                                        />
                                    </div>
                                ))}
                            </SliderDashboard>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
