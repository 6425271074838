import React, { useCallback, useEffect, useState } from 'react';
import ListViewOfferLink from './ListViewOfferLink';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { IsMobileScreen } from '../../../../../Constants/Constants';

export default function LinkOutletTable(props) {
    const { selectedPresetForLink, setOutletsToUpdate, outletList } = props;
    const isMobileScreen = IsMobileScreen();
    const [linkedOutlets, setLinkedOutlets] = useState(() => {
        if (selectedPresetForLink?.menu_outlets) {
            return selectedPresetForLink.menu_outlets
                .filter((outlet) => outlet.is_active === 1)
                .map((outlet) => outlet.outlet_id);
        }
        return [];
    });

    const getIsEnable = (outletId) => {
        return linkedOutlets.includes(outletId);
    };

    const handleChangeLinking = async (doLink, outletId) => {
        let updatedLinkedOutlets;

        setLinkedOutlets((prevLinkedOutlets) => {
            updatedLinkedOutlets = doLink
                ? [...prevLinkedOutlets, outletId]
                : prevLinkedOutlets.filter((id) => id !== outletId);
            return updatedLinkedOutlets;
        });

        setOutletsToUpdate((prevUpdates) => {
            const updatedUpdates = { ...prevUpdates };

            const newLinkingIds = [];
            const newUnlinkingIds = [];

            outletList.forEach((outlet) => {
                const isCurrentlyLinked = updatedLinkedOutlets.includes(outlet.outletId); // Use the outer scope variable
                const wasPreviouslyLinked =
                    selectedPresetForLink?.menu_outlets?.find((menuOutlet) => menuOutlet.outlet_id === outlet.outletId)
                        ?.is_active === 1;

                if (isCurrentlyLinked && !wasPreviouslyLinked) {
                    newLinkingIds.push(outlet.outletId);
                } else if (!isCurrentlyLinked && wasPreviouslyLinked) {
                    newUnlinkingIds.push(outlet.outletId);
                }
            });

            updatedUpdates.linking_outlets_ids = newLinkingIds;
            updatedUpdates.unlinking_outlets_ids = newUnlinkingIds;

            return updatedUpdates;
        });
    };
    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[90px]'>STATUS</th>
                                <th className='px-6 min-w-[302px]'>OUTLET NAME</th>
                                <th className='px-6 min-w-[102px] lg:min-w-[135px]'>STATE</th>
                                <th className='px-6 min-w-[92px] lg:min-w-[151px]'>CITY</th>
                                <th className='px-6 min-w-[334px]'>ADDRESS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {outletList &&
                                outletList?.length > 0 &&
                                outletList.map((el, index) => {
                                    const isEnabled = getIsEnable(el.outletId);
                                    return (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none justify-center h-[70px]'
                                            key={index}>
                                            <td className='px-6'>
                                                <ToggleSwitch
                                                    canChange={true}
                                                    isEnable={isEnabled}
                                                    setIsEnabled={(value) => handleChangeLinking(value, el.outletId)}
                                                />
                                            </td>
                                            <td className='px-6'>{el.displayName}</td>
                                            <td className='px-6'>
                                                {el.outletData?.outlet_address?.translations?.state?.[1]}
                                            </td>
                                            <td className='px-6'>
                                                {el.outletData?.outlet_address?.translations?.city?.[1]}
                                            </td>
                                            <td className='px-6'>
                                                {el.outletData?.outlet_address?.translations?.address_line_1?.[1]}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className=''>
                    {outletList &&
                        outletList?.length > 0 &&
                        outletList.map((el, index) => {
                            const isEnabled = getIsEnable(el.outletId);
                            return (
                                <div
                                    className='mt-2'
                                    key={index}>
                                    <ListViewOfferLink
                                        displayName={el.displayName}
                                        state={el.outletData?.outlet_address?.translations?.state?.[1]}
                                        city={el.outletData?.outlet_address?.translations?.city?.[1]}
                                        address={el.outletData?.outlet_address?.translations?.address_line_1?.[1]}
                                        isEnable={isEnabled}
                                    />
                                </div>
                            );
                        })}
                </div>
            )}
        </>
    );
}
