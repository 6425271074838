import ContentLoader from 'react-content-loader';
import { SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export const CategoryLoader = () => {
    return (
        <ContentLoader
            width={400}
            height={440}
            className='border-2 px-2 rounded-lg'
            viewBox='0 0 400 440'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'>
            {/* Title Bar */}
            <rect
                x='10'
                y='10'
                rx='4'
                ry='4'
                width='120'
                height='20'
            />
            <rect
                x='350'
                y='10'
                rx='4'
                ry='4'
                width='40'
                height='20'
            />

            {/* Image Placeholder */}
            <rect
                x='10'
                y='45'
                rx='6'
                ry='6'
                width='150'
                height='100'
            />

            {/* Category Name */}
            <rect
                x='190'
                y='55'
                rx='4'
                ry='4'
                width='100'
                height='25'
            />
            <rect
                x='190'
                y='95'
                rx='4'
                ry='4'
                width='180'
                height='20'
            />

            {/* Description */}
            <rect
                x='16'
                y='153'
                rx='4'
                ry='4'
                width='60'
                height='20'
            />

            {/* Tile Position & Theme */}
            <rect
                x='190'
                y='180'
                rx='4'
                ry='4'
                width='160'
                height='18'
            />
            <rect
                x='10'
                y='180'
                rx='4'
                ry='4'
                width='120'
                height='18'
            />

            {/* Category Visibility */}
            <rect
                x='10'
                y='250'
                rx='4'
                ry='4'
                width='100'
                height='12'
            />
            <rect
                x='10'
                y='220'
                rx='4'
                ry='4'
                width='130'
                height='12'
            />
            <rect
                x='200'
                y='240'
                rx='4'
                ry='4'
                width='110'
                height='22'
            />
            <rect
                x='10'
                y='270'
                rx='4'
                ry='4'
                width='140'
                height='12'
            />
            <rect
                x='10'
                y='310'
                rx='4'
                ry='4'
                width='90'
                height='12'
            />
            <rect
                x='10'
                y='340'
                rx='4'
                ry='4'
                width='160'
                height='12'
            />

            {/* Bottom Actions */}
            <rect
                x='10'
                y='380'
                rx='4'
                ry='4'
                width='120'
                height='20'
            />
            <rect
                x='140'
                y='380'
                rx='4'
                ry='4'
                width='100'
                height='20'
            />
        </ContentLoader>
    );
};

export const DishDetailLoader = () => {
    const numLoaders = 8;

    const loadersArray = Array.from({ length: numLoaders }, (_, index) => index);

    return (
        <div>
            {loadersArray.map((_, index) => (
                <SingleLoader
                    key={index}
                    className={`h-14 rounded-lg${index === 0 ? ' pt-0.5' : ' mt-4'}`}
                />
            ))}
        </div>
    );
};
