import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWindowSize } from '@uidotdev/usehooks';
import { ReactComponent as LiveOrderIcon } from '../../../Assets/live-order.svg';
import { ReactComponent as PastOrderIcon } from '../../../Assets/past-order.svg';
import { ReactComponent as FailedOrderIcon } from '../../../Assets/failed-order.svg';
import { ReactComponent as BillPaymentIcon } from '../../../Assets/bill-payments.svg';
import { Tab } from '../../../Components/Tabs/Tabs';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { IsIpadScreen, IsMobileScreen } from '../../../Constants/Constants';
import MobileOrderingMode from '../../../Components/PosComponents/MobileOrderingMode';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import FilterLoader from '../../../Components/ContentLoader/FilterLoader';

const Header = ({
    setShowPopup,
    setIsKotView,
    setSelectedOrderingMode,
    setSelectedFilter,
    allOrderingModeCounts,
    resetState,
    orderType,
    isLoading,
    selectedOrderingMode,
}) => {
    const orderingModes = useSelector((state) => state.outlet?.orderingModes);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isMobileScreen = IsMobileScreen();
    const { width: screenWidth } = useWindowSize();
    const [showViewDropdown, setShowViewDropdown] = useState(false);
    const viewOptionRef = useRef(null);

    const orderStatusTabs = [
        { label: 'Live order', icon: LiveOrderIcon, path: '/orders/live' },
        { label: 'Past order', icon: PastOrderIcon, path: '/orders/past' },
        { label: 'Failed order', icon: FailedOrderIcon, path: '/orders/failed' },
        { label: 'Bill payment', icon: BillPaymentIcon, path: '/orders/bill-payment' },
    ];

    const handleOrderStatusClick = (path) => {
        if (path.includes(orderType)) return;
        resetState();
        navigate(path);
    };

    const getOrderCountById = useCallback(
        (id) => {
            if (!allOrderingModeCounts || !Array.isArray(allOrderingModeCounts)) return '0';
            const foundMode = allOrderingModeCounts.find((mode) => mode.ordering_mode_id === id);
            return foundMode ? foundMode.order_count.toString() : '0';
        },
        [allOrderingModeCounts]
    );
    const totalOrderCount = useMemo(() => {
        if (!allOrderingModeCounts || !Array.isArray(allOrderingModeCounts)) return '0';
        if (allOrderingModeCounts.length === 0) return '0';

        const total = allOrderingModeCounts.reduce((sum, mode) => {
            return sum + (mode.order_count || 0);
        }, 0);

        return total.toString();
    }, [allOrderingModeCounts]);

    const orderingModeMenuItems = useMemo(() => {
        const allItem = {
            item: 'All',
            value: 'all',
            badgeText: totalOrderCount,
            onClick: () => setSelectedOrderingMode('all'),
            isSelected: selectedOrderingMode === 'all',
        };

        const modeItems =
            orderingModes?.map((item) => ({
                item: item.display_name,
                value: item.tab,
                badgeText: getOrderCountById(item.id),
                onClick: () => setSelectedOrderingMode(item.tab),
                isSelected: selectedOrderingMode === item.tab,
            })) || [];

        return [allItem, ...modeItems];
    }, [orderingModes, totalOrderCount, getOrderCountById, setSelectedOrderingMode, selectedOrderingMode]);

    const selectedItem = useMemo(() => {
        return orderingModeMenuItems.find((item) => item.value === selectedOrderingMode);
    }, [orderingModeMenuItems, selectedOrderingMode]);

    const orderStatusLive = [
        { item: 'All', value: 'all', onClick: () => setSelectedFilter('all') },
        { item: 'Pending', value: 'placed', onClick: () => setSelectedFilter('placed') },
        { item: 'Preparing', value: 'restaurant_accepted', onClick: () => setSelectedFilter('restaurant_accepted') },
        { item: 'Prepared', value: 'restaurant_ready', onClick: () => setSelectedFilter('restaurant_ready') },
        { item: 'Dispatched', value: 'rider_picked_up', onClick: () => setSelectedFilter('rider_picked_up') },
    ];

    const orderStatusPast = [
        { item: 'All', value: 'all', onClick: () => setSelectedFilter('all') },
        { item: 'Delivered', value: 'delivered', onClick: () => setSelectedFilter('delivered') },
        { item: 'Cancelled', value: 'cancelled', onClick: () => setSelectedFilter('cancelled') },
        { item: 'Refunded', value: 'refunded', onClick: () => setSelectedFilter('refunded') },
    ];

    const orderStatusFailed = [
        { item: 'All', value: 'all', onClick: () => setSelectedFilter('all') },
        { item: 'Refund successfully', value: 'refund successfully', onClick: () => setSelectedFilter('refunded') },
        { item: 'Refund failed', value: 'refund failed', onClick: () => setSelectedFilter('failed') },
        { item: 'Refund initiated', value: 'refund initiated', onClick: () => setSelectedFilter('initiated') },
    ];

    const viewControllerDropdown = [
        { item: 'List view', onClick: () => setIsKotView(false) },
        { item: 'KOT view', onClick: () => setIsKotView(true) },
    ];

    useOutsideClick(viewOptionRef, () => setShowViewDropdown(false));

    // Reset drop-down when tab changes
    useEffect(() => {
        setSelectedOrderingMode('all');
        setSelectedFilter('all');
    }, [setSelectedFilter, setSelectedOrderingMode]);

    const renderOrderingModesDropdown = () => (
        <div className='max-w-[331px] lg:max-w-[200px] w-full md:max-w-full'>
            <DropDownTabs
                menuItems={orderingModeMenuItems}
                selectedItem={selectedItem}
                fixedLabel={screenWidth > 1023 ? 'Ordering mode ' : 'O.M.'}
                menuButtonStyle='border-neutral-300'
                badgeTextStyle='bg-neutral-200'
                dropDownIconFill='#131126'
                boxStyle='border-neutral-300'
                textColor='text-neutral-900'
            />
        </div>
    );

    const renderFilterDropdown = (filterItems) => (
        <div className='min-w-[180px] lg:min-w-[180px] w-[180px] md:max-w-full'>
            <DropDownTabs
                fixedLabel='Filter'
                menuItems={filterItems}
                boxStyle='border-neutral-300'
                textColor='text-neutral-900'
                menuButtonStyle='border-neutral-300'
                dropDownIconFill='#131126'
            />
        </div>
    );

    const renderViewDropdown = () =>
        !isMobileScreen && (
            <div className='relative'>
                <div
                    className='flex flex-col gap-1 cursor-pointer p-2 -m-2'
                    onClick={() => setShowViewDropdown((prev) => !prev)}>
                    {[...Array(3)].map((_, i) => (
                        <div
                            key={i}
                            className='bg-black h-1 w-1 rounded-full'
                        />
                    ))}
                </div>
                {showViewDropdown && (
                    <div
                        className='border border-neutral-300 rounded-xl px-4 w-[112px] absolute top-6 bg-white right-0'
                        ref={viewOptionRef}>
                        {viewControllerDropdown.map((el, index) => (
                            <div
                                key={index}
                                className='py-1.5 cursor-pointer paragraph-medium-regular'
                                onClick={() => {
                                    el.onClick();
                                    setShowViewDropdown(false);
                                }}>
                                <span>{el.item}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );

    return (
        <>
            <div className='flex flex-row justify-between items-center pb-4 mb-4 border-b border-neutral-300 md:flex-col md:gap-4'>
                {screenWidth > 1200 ? (
                    <div className='flex flex-row items-center'>
                        {orderStatusTabs.map((tab, index) => (
                            <div
                                key={index}
                                className='mr-4 xl:mr-2'
                                onClick={() => handleOrderStatusClick(tab.path)}>
                                <Tab
                                    label={tab.label}
                                    iconDefault={<tab.icon />}
                                    iconClick={<tab.icon stroke='#6C5DD3' />}
                                    isActive={pathname === tab.path}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='w-full max-w-[213px] md:max-w-full'>
                        <DropDownTabs
                            menuItems={orderStatusTabs.map((tab) => ({
                                item: tab.label,
                                icon: <tab.icon />,
                                iconClick: <tab.icon stroke='#6C5DD3' />,
                                onClick: () => handleOrderStatusClick(tab.path),
                            }))}
                            selectedItem={
                                orderStatusTabs
                                    .map(
                                        (tab) =>
                                            pathname === tab.path && {
                                                item: tab.label,
                                                icon: <tab.icon />,
                                                iconClick: <tab.icon stroke='#6C5DD3' />,
                                                onClick: () => handleOrderStatusClick(tab.path),
                                            }
                                    )
                                    .filter(Boolean)?.[0] ?? {}
                            }
                        />
                    </div>
                )}

                {pathname === '/orders/live' && (
                    <div className='flex flex-row items-center gap-4 lg:gap-2 md:w-full h-[3rem]'>
                        {!isLoading ? renderOrderingModesDropdown() : <FilterLoader />}
                        {renderFilterDropdown(orderStatusLive)}
                        {renderViewDropdown()}
                    </div>
                )}

                {pathname === '/orders/past' && (
                    <div className='flex flex-row items-center gap-4 lg:gap-2 md:w-full h-[3rem]'>
                        {!isLoading ? renderOrderingModesDropdown() : <FilterLoader />}
                        {renderFilterDropdown(orderStatusPast)}
                    </div>
                )}
                {pathname === '/orders/failed' && (
                    <div className='flex flex-row items-center gap-4 lg:gap-2 md:w-full h-[3rem]'>
                        {!isLoading ? renderOrderingModesDropdown() : <FilterLoader />}
                        {renderFilterDropdown(orderStatusFailed)}
                    </div>
                )}
            </div>

            {isMobileScreen && <MobileOrderingMode orderingModes={orderingModes} />}
        </>
    );
};

export default React.memo(Header);
