import React from 'react';
import ContentLoader from 'react-content-loader';

const DeliveryCardLoader = ({ height = 400 }) => {
    return (
        <ContentLoader
            width={450}
            height={height}
            viewBox='0 0 400 400'
            className='border-2 rounded-lg'
            backgroundColor='#f5f5f5'
            foregroundColor='#ebebeb'>
            {/* Card Header */}
            <rect
                x='10'
                y='20'
                rx='5'
                ry='5'
                width='120'
                height='20'
            />
            <rect
                x='290'
                y='20'
                rx='5'
                ry='5'
                width='100'
                height='20'
            />

            {/* New Card Header Loaders */}
            <rect
                x='10'
                y='50'
                rx='5'
                ry='5'
                width='180'
                height='20'
            />
            <rect
                x='210'
                y='50'
                rx='5'
                ry='5'
                width='180'
                height='20'
            />

            {/* Rider Details */}
            <rect
                x='10'
                y='90'
                rx='5'
                ry='5'
                width='380'
                height='105'
            />

            {/* Divider */}
            <rect
                x='10'
                y='210'
                rx='5'
                ry='5'
                width='380'
                height='3'
            />

            {/* Ordered Dishes */}
            <rect
                x='10'
                y='220'
                rx='5'
                ry='5'
                width='100'
                height='15'
            />
            <rect
                x='320'
                y='220'
                rx='5'
                ry='5'
                width='70'
                height='15'
            />

            {/* Total Amount Divider */}
            <rect
                x='10'
                y='260'
                rx='5'
                ry='5'
                width='380'
                height='3'
            />

            {/* Added Elements Below Divider */}
            <rect
                x='10'
                y='270'
                rx='5'
                ry='5'
                width='140'
                height='25'
            />
            <rect
                x='300'
                y='270'
                rx='5'
                ry='5'
                width='90'
                height='25'
            />

            {/* Bill Amount */}
            <rect
                x='10'
                y='240'
                rx='5'
                ry='5'
                width='200'
                height='15'
            />
            <rect
                x='340'
                y='240'
                rx='5'
                ry='5'
                width='50'
                height='15'
            />

            {/* Buttons */}
            <rect
                x='25'
                y='305'
                rx='8'
                ry='8'
                width='165'
                height='45'
            />
            <rect
                x='214'
                y='305'
                rx='8'
                ry='8'
                width='165'
                height='45'
            />
            <rect
                x='10'
                y='355'
                rx='8'
                ry='8'
                width='380'
                height='45'
            />
        </ContentLoader>
    );
};

const DeliveryCardsLoaderKOT = () => {
    return (
        <div className='flex flex-row justify-between gap-4'>
            <DeliveryCardLoader height={500} />
            <DeliveryCardLoader height={550} />
            <DeliveryCardLoader height={440} />
        </div>
    );
};

export default DeliveryCardsLoaderKOT;
