import React, { useRef, useState } from 'react';
import './styles.css';
import OrderHeader from '../../../../Components/KOT/Components/OrderHeader';
import OrderDishes, { ItemsComponent } from '../../../../Components/KOT/Components/OrderDishes';
import TotalBill from '../../../../Components/KOT/Components/TotalBill';
import OrderActions from '../../../../Components/KOT/Components/OrderActions';
import { useWindowSize } from '@uidotdev/usehooks';
import AssignedOrder from '../../../../Components/KOT/Components/AssignedOrder';
import { NotAssignedRider } from '../../../../Components/KOT/Components/NotAssignedRider';

export default function ListViewOfKOT(props) {
    const {
        orderDetail,
        setShowPopup,
        onReady,
        onComplete,
        orderItems,
        setCancelRider,
        handleClickClose,
        isLoading,
        fetchData,
    } = props;
    const [showWithItems, setShowWithItems] = useState(false);

    const kotRef = useRef();

    const { height: screenHeight } = useWindowSize();
    const isRiderAssigned =
        orderDetail?.dunzo_order ||
        orderDetail?.elt_order ||
        orderDetail?.pidge_order ||
        orderDetail?.porter_order ||
        orderDetail?.rider_order ||
        orderDetail?.shadowfax_order ||
        orderDetail?.wefast_order;

    const isRiderServiceAssigned = orderDetail?.rider;

    return (
        <>
            <div
                className='px-4 overflow-auto scrollbar-style -mt-1 pb-3'
                // style={{ height: screenHeight - 250 }}
                ref={kotRef}>
                {!isRiderServiceAssigned && isRiderAssigned && <NotAssignedRider orderDetail={orderDetail} />}
                {isRiderServiceAssigned && (
                    <AssignedOrder
                        orderDetail={orderDetail}
                        setCancelRider={setCancelRider}
                        setShowPopup={setShowPopup}
                        handleClickClose={handleClickClose}
                        fetchData={fetchData}
                    />
                )}
                <OrderHeader
                    // orderingMode={orderingMode}
                    // icon={icon}
                    // label={label}
                    // listView
                    setShowPopup={setShowPopup}
                    orderDetail={orderDetail}
                    isLoading={isLoading}
                />
                <OrderDishes
                    orderItems={orderItems}
                    isLoading={isLoading}
                />
                <ItemsComponent
                    items={orderDetail?.cart?.cart_items}
                    handleClickWithDropDown={() => setShowWithItems((prevState) => !prevState)}
                    showWithItems={showWithItems}
                    isLoading={isLoading}
                />
                <div className='mb-4 pb-4 border-b border-neutral-300'>
                    <TotalBill
                        orderingMode={orderDetail?.ordering_mode}
                        orderDetail={orderDetail}
                        isLoading={isLoading}
                        isBillPaid={orderDetail?.paid_at}
                    />
                </div>
                <OrderActions
                    orderingMode={orderDetail?.ordering_mode}
                    setShowPopup={setShowPopup}
                    orderDetail={orderDetail}
                    onReady={onReady}
                    onComplete={onComplete}
                />
            </div>
        </>
    );
}
