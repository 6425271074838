import React, { useEffect, useState } from 'react';
import { IsMobileScreen } from '../../Constants/Constants';
import { format } from 'date-fns';
import CustomerOfferDetails from './Components/CustomerOfferDetails';
import APIV5 from '../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';
import ConfirmDeletePopup from '../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import Layout from './Components/Layout';
import RatingComponent from './Components/RatingComponent';
import { useParams } from 'react-router-dom';
import Complaint from './Components/Complaint';
import ComplaintDetailsPopup from './Popup/ComplaintDetailsPopup';
import RejectComplaintPopup from './Popup/RejectComplaintPopup';
import RefundForComplaintPopup from './Popup/RefundForComplaintPopup';
import ResolvedComplaintDetails from './Popup/ResolvedComplaintDetails';
import ComplaintImagePopup from './Popup/ComplaintImagePopup';

export default function OrderRating() {
    const today = new Date();
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);
    const [showCustomerReview, setshowCustomerReview] = useState(false);

    const [showCustomerOffer, setshowCustomerOffer] = useState(false);
    const [selectedDates, setSelectedDates] = useState([today, today]);
    const [appliedOrderFilter, setAppliedOrderFilter] = useState(null);
    const [appliedRiderFilter, setAppliedRiderFilter] = useState({});
    const [orderRatingArray, setOrderRatingArray] = useState([]);
    const [riderRatingArray, setRiderRatingArray] = useState([]);
    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const [orderRatingDetails, setOrderRatingDetails] = useState([]);
    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);
    const [updatedpaginationStatus, setUpdatedpaginationStatus] = useState({});
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [isPopupLoading, setIsPopupLoading] = useState(false);
    const [popupDetails, setPopupDetails] = useState(null);
    const [deleteReviewId, setDeleteReviewId] = useState(null);
    const [deleted, setDeleted] = useState(false);
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
    const [paginationChanged, setPaginationChanged] = useState(false);
    const [createOfferDetails, setCreateOfferDetails] = useState({ partial_amount: null, id: null, gross_total: null });
    const [offerPopupDetails, setOfferPopupDetails] = useState([]);
    const [isOfferDetailLoading, setIsOfferDetailLoading] = useState(false);
    const [offerCreated, setOfferCreated] = useState(false);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [showOfferpage, setShowOfferPage] = useState(false);

    const handleConfirmDeletePopup = () => {
        setShowConfirmDeletePopup(!showConfirmDeletePopup);
    };

    const [selectedOrderId, setSelectedOrderId] = useState(null);

    const [offerId, setOfferid] = useState(null);
    const toggleCustomerReviewPopup = (orderId) => {
        setshowCustomerReview(!showCustomerReview);
        setSelectedOrderId(orderId);
    };

    const toggleCustomerOfferPopup = () => {
        setshowCustomerOffer(!showCustomerOffer);
    };

    const { tab } = useParams();

    const isMobileScreen = IsMobileScreen();

    useEffect(() => {
        switch (appliedOrderFilter?.value) {
            case 'bad':
                setOrderRatingArray([0.0, 2.9]);
                break;
            case 'average':
                setOrderRatingArray([3.0, 3.9]);
                break;
            case 'good':
                setOrderRatingArray([4.0, 5.0]);
                break;
            case null:
                setOrderRatingArray([]);
                break;
            default:
                setOrderRatingArray([]);
                break;
        }
    }, [appliedOrderFilter]);

    useEffect(() => {
        switch (appliedRiderFilter?.value) {
            case 'bad':
                setRiderRatingArray([0.0, 2.9]);
                break;
            case 'average':
                setRiderRatingArray([3.0, 3.9]);
                break;
            case 'good':
                setRiderRatingArray([4.0, 5.0]);
                break;
            case null:
                setRiderRatingArray([]);
                break;
            default:
                setRiderRatingArray([]);
                break;
        }
    }, [appliedRiderFilter]);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        return {
            order_rating: { from: orderRatingArray[0]?.toFixed(1), to: orderRatingArray[1]?.toFixed(1) },
            rider_rating: { from: riderRatingArray[0]?.toFixed(1), to: riderRatingArray[1]?.toFixed(1) },
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
        };
    };

    const fetchOrderRatingDetail = async (params) => {
        setIsLoading(true);
        try {
            const defaultParamsData = defaultParams();
            const response = await APIV5.get(`/outlets/${selectedOutletId}/order-rating`, {
                params: {
                    ...params,
                    ...defaultParamsData,
                    per_page: params.per_page ?? updatedpaginationStatus.resultPerPage,
                    page: params.page ?? updatedpaginationStatus.selectedPage,
                },
            });

            setOrderRatingDetails(response.orderFeedback.data);
            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.orderFeedback.last_page,
                    selectedPage: response.orderFeedback.current_page,
                };
            });
            setIsLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const updatePaginationState = (updates) => {
        setUpdatedpaginationStatus(updates);
        const newSelectedPage =
            updates.selectedPage !== undefined ? updates.selectedPage : paginationState.selectedPage;
        const params = {
            page: updates.selectedPage ?? paginationState.selectedPage,
            per_page: updates.resultPerPage ?? paginationState.resultPerPage,
            order_rating: orderRatingArray,
            rider_rating: riderRatingArray,
        };

        fetchOrderRatingDetail(params, () => {
            if (newSelectedPage === paginationState.selectedPage) {
                setPaginationState((prevState) => ({
                    ...prevState,
                    per_page: updates.resultPerPage ?? paginationState.resultPerPage,
                    selectedPage: newSelectedPage,
                    ...updates,
                }));
            }
        });
    };

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            updatePaginationState(data);
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        updatePaginationState({ ...data, selectedPage: 1 });
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        }
        const params = {
            order_rating: orderRatingArray,
            rider_rating: riderRatingArray,
            page: paginationState.selectedPage,
            per_page: paginationState.resultPerPage,
        };
        fetchOrderRatingDetail(params);
    }, [
        selectedDates,
        orderRatingArray,
        riderRatingArray,
        paginationState.selectedPage,
        paginationState.resultPerPage,
        deleted,
        offerCreated,
        selectedOutlet,
    ]);

    useEffect(() => {
        setPaginationChanged(true);
        const timer = setTimeout(() => {
            setPaginationChanged(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [paginationState]);

    const fetchOrderDetailPopup = async (id) => {
        try {
            setIsPopupLoading(true);
            const response = await APIV5.get(`orders/${id}`);

            setPopupDetails(response.order_details);
            if (response.success === true) {
                setIsPopupLoading(false);
            }
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        if (showCustomerReview && selectedOrderId) {
            fetchOrderDetailPopup(selectedOrderId);
        }
    }, [showCustomerReview, selectedOrderId]);

    const formattedDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'dd MMM yyyy') : 'N/A';
    };

    const calculateTotalQuantity = (cartItems) =>
        cartItems?.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0) || 0;

    const popupOrderingMode = {
        'dine_in': 'Dine-In',
        'delivery': 'Delivery',
        'takeaway': 'Takeaway',
        'CASH_ON_DELIVERY': 'Cash',
        'CREDIT_CARD/DEBIT_CARD': 'Card',
        'CREDIT_CARD': 'Credit card',
        'DEBIT_CARD': 'Debit card',
        'UPI': 'UPI',
        'UPI_INTENT': 'UPI',
        'BALANCE': 'Balance',
    };

    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours, 10);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 24 || 24;
        const formattedTime = `${formattedHour}:${minutes} ${period}`;

        return formattedTime;
    }

    const popupData = [
        {
            item: 'Order bill name',
            value: popupDetails && popupDetails?.order_bill_name ? popupDetails?.order_bill_name : 'N/A',
        },

        {
            item: 'Order date',
            value: popupDetails && popupDetails?.order_date ? formattedDate(popupDetails?.order_date) : 'N/A',
        },
        {
            item: 'Payment method',
            value:
                popupDetails && popupDetails?.payment_method ? popupOrderingMode[popupDetails?.payment_method] : 'N/A',
        },

        {
            item: 'Ordered via',
            value: popupDetails && popupDetails?.order_via ? popupDetails?.order_via : 'N/A',
        },
        {
            item: 'Delivery Area',
            value: popupDetails && popupDetails?.delivery_area ? popupDetails?.delivery_area : 'N/A',
        },
        {
            item: 'Order type',
            value: popupDetails && popupDetails?.order_type ? popupOrderingMode[popupDetails?.order_type] : 'N/A',
        },

        {
            item: 'Order time',
            value: popupDetails && popupDetails?.order_time ? formatTime(popupDetails?.order_time) : 'N/A',
        },

        {
            item: 'Dishes ordered',
            value:
                popupDetails && popupDetails?.cart?.cart_items
                    ? calculateTotalQuantity(popupDetails?.cart?.cart_items)
                    : 'N/A',
        },
        {
            item: 'Platform',
            value: popupDetails && popupDetails?.platform ? popupDetails?.platform : 'N/A',
        },
        {
            item: 'Delivery Distance',
            value: popupDetails && popupDetails?.delivery_distance ? `${popupDetails?.delivery_distance} kms` : '0 kms',
        },
    ];

    const feedbacks = {
        orderFeedback: popupDetails?.order_feedback,
        riderFeedback: popupDetails?.rider_feedback,
        date: formattedDate(popupDetails?.order_date),
    };

    const extraCharges = [
        {
            gst_amount: popupDetails?.cart?.gst_amount,
            packaging_charge: popupDetails?.cart?.packaging_charge,
            convenience_charge: popupDetails?.cart?.convenience_charge,
        },
    ];

    const otherAmount = [
        { type: 'Gross total', value: popupDetails?.gross_total?.toFixed(2) },
        {
            type: 'Other charges & taxes',
            value: (
                popupDetails?.cart?.gst_amount +
                popupDetails?.cart?.packaging_charge +
                popupDetails?.cart?.convenience_charge
            )?.toFixed(2),
        },
        {
            type: 'Delivery charge',
            value: popupDetails?.cart?.delivery_charge?.toFixed(2),
        },
    ];

    const handleDeteteReview = async () => {
        const response = await APIV5.delete(`outlets/${selectedOutletId}/order-feedbacks/${deleteReviewId}`);
        if (response.success === true) {
            setshowCustomerReview(!showCustomerReview);
            setShowConfirmDeletePopup(false);
            setDeleted(!deleted);
        }
    };

    const handleCloseDeletePopup = () => {
        setShowConfirmDeletePopup(false);
    };

    const handleOfferCreate = async () => {
        try {
            const response = await APIV5.post(`orders/${createOfferDetails.id}/create-offer`, {
                discount_amount: createOfferDetails.partial_amount ?? createOfferDetails.gross_total,
            });
            setshowCustomerReview(!showCustomerReview);
            setOfferCreated(!offerCreated);
            setShowOfferPage(false);
        } catch (error) {
            console.log('Error creating offer:', error);
        }
    };

    const fetchOfferDetails = async (offerId) => {
        try {
            setIsOfferDetailLoading(true);
            const response = await APIV5.get(`offers/${offerId}/offer-details`);
            setOfferPopupDetails(response.orderFeedback);
            setIsOfferDetailLoading(false);
            setshowCustomerReview(false);
            setshowCustomerReview(false);
        } catch (error) {
            console.log('Error creating offer:', error);
        }
    };

    const [showComplaintDetails, setShowComplaintDetails] = useState(false);

    const [showRejectComplaintPopup, setShowRejectComplaintPopup] = useState(false);

    const [showAcceptComplaintPopup, setShowAcceptComplaintPopup] = useState(false);

    const [showResolvedComplaintPopup, setShowResolvedComplaintPopup] = useState(false);

    const [showComplaintImage, setShowComplaintImage] = useState(false);

    if (isMobileScreen && showCustomerOffer) {
        return (
            <CustomerOfferDetails
                handleClickOrderId={toggleCustomerOfferPopup}
                offerPopupDetails={offerPopupDetails}
                selectedLangaugeId={selectedLangaugeId}
                isOfferDetailLoading={isOfferDetailLoading}
            />
        );
    }

    const handleClickSelectOffer = () => {
        setShowOfferPage(!showOfferpage);
    };

    const handleSubmit = async (data) => {
        try {
            setIsLoading(true);

            const data = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/complaints`,
                {
                    rejectionReason: data.rejectionReason,
                    comment: data.comment,
                }
            );
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Layout showCustomerReview={showCustomerReview}>
                {tab === 'rating' ? (
                    <RatingComponent
                        setAppliedOrderFilter={setAppliedOrderFilter}
                        setAppliedRiderFilter={setAppliedRiderFilter}
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                        toggleCustomerReviewPopup={toggleCustomerReviewPopup}
                        toggleCustomerOfferPopup={toggleCustomerOfferPopup}
                        orderRatingDetails={orderRatingDetails}
                        setDeleteReviewId={setDeleteReviewId}
                        fetchOrderRatingDetail={fetchOrderRatingDetail}
                        isLoading={isLoading}
                        paginationState={paginationState}
                        paginationChanged={paginationChanged}
                        setCreateOfferDetails={setCreateOfferDetails}
                        fetchOfferDetails={fetchOfferDetails}
                        setOfferid={setOfferid}
                        handlePagination={handlePagination}
                        appliedOrderFilter={appliedOrderFilter}
                        appliedRiderFilter={appliedRiderFilter}
                        deleted={deleted}
                    />
                ) : (
                    <Complaint
                        selectedRestaurantId={selectedRestaurantId}
                        selectedOutletId={selectedOutletId}
                        setShowComplaintDetails={setShowComplaintDetails}
                        setShowResolvedComplaintPopup={setShowResolvedComplaintPopup}
                        setShowComplaintImage={setShowComplaintImage}
                    />
                )}
            </Layout>

            {showCustomerReview && (
                <OrderDetailsPopup
                    handleClickOrderId={toggleCustomerReviewPopup}
                    isDelivered={popupDetails?.delivered}
                    code={popupDetails?.last_four_character_order_label}
                    isPopupLoading={isPopupLoading}
                    orderedDishes={popupDetails?.cart?.cart_items}
                    orderDetails={popupData}
                    isRiderFeedback={popupData[5]?.value}
                    extraCharges={extraCharges}
                    otherAmount={otherAmount}
                    netTotal={popupDetails?.total_bill_amount}
                    feedbacks={feedbacks}
                    isPaid={popupDetails?.paid}
                    grossTotal={popupDetails?.gross_total}
                    offerId={popupDetails?.offer_id}
                    page='orderRating'
                    popupDetails={popupDetails}
                    handleClickClose={toggleCustomerReviewPopup}
                    handleConfirmDeletePopup={handleConfirmDeletePopup}
                    setCreateOfferDetails={setCreateOfferDetails}
                    handleOfferCreate={handleOfferCreate}
                    selectedLangaugeId={selectedLangaugeId}
                    toggleCustomerOfferPopup={toggleCustomerOfferPopup}
                    fetchOfferDetails={fetchOfferDetails}
                    viewOfferId={offerId}
                    handleClickSelectOffer={handleClickSelectOffer}
                    showOfferpage={showOfferpage}
                />
            )}
            {showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    header={<h2 className='paragraph-large-medium ml-2'>Delete the review?</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            Are you sure you want to delete this review?
                        </p>
                    }
                    handleDelete={handleDeteteReview}
                    handleClickClose={handleCloseDeletePopup}
                />
            )}
            {showCustomerOffer && (
                <>
                    <CustomerOfferDetails
                        handleClickOrderId={toggleCustomerOfferPopup}
                        offerPopupDetails={offerPopupDetails}
                        selectedLangaugeId={selectedLangaugeId}
                        isOfferDetailLoading={isOfferDetailLoading}
                    />
                </>
            )}

            {showComplaintDetails && (
                <ComplaintDetailsPopup
                    handleClickClose={() => setShowComplaintDetails(false)}
                    setShowRejectComplaintPopup={setShowRejectComplaintPopup}
                    setShowAcceptComplaintPopup={setShowAcceptComplaintPopup}
                    showComplaintDetails={showComplaintDetails}
                />
            )}

            {showRejectComplaintPopup && (
                <RejectComplaintPopup
                    handleClickClose={() => {
                        setShowRejectComplaintPopup(false);
                        setShowComplaintDetails(true);
                    }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                />
            )}

            {showAcceptComplaintPopup && (
                <RefundForComplaintPopup
                    handleClickClose={() => {
                        setShowAcceptComplaintPopup(false);
                        setShowComplaintDetails(true);
                    }}
                />
            )}

            {showResolvedComplaintPopup && (
                <ResolvedComplaintDetails handleClickClose={() => setShowResolvedComplaintPopup(false)} />
            )}

            {showComplaintImage && <ComplaintImagePopup handleClickClose={() => setShowComplaintImage(false)} />}
        </>
    );
}
