import React, { useCallback } from 'react';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';
import { ReactComponent as SelectIcon } from '../../../../../Assets/select.svg';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { useSelector } from 'react-redux';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import {
    LargeDestructiveButton,
    LargePrimaryButton,
    LargeTertiaryButton,
} from '../../../../../Components/Buttons/Button';
import APIV2 from '../../../../../api/axios/APIV2';

export default function HeaderCataloguePreset(props) {
    const {
        fetchRestaurantOfferList,
        showCancelDelete,
        handleClickCancel,
        isDisable,
        selectedOffer,
        setAppliedFilter,
        setPageState,
        handleFetchCategoryDetails,
        handleBulkSelect,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleClickDelete = async () => {
        try {
            const response = await APIV2.delete(`restaurants/${selectedRestaurantId}/offers`, {
                params: {
                    offer_ids: selectedOffer,
                },
            });

            if (response.success) {
                handleFetchCategoryDetails();
                // handleCancel();
            }
        } catch (error) {
            console.log(error);
        }
    };

    // eslint-disable-next-line
    const handleSearch = useCallback(
        _.debounce(async (keyword) => {
            setAppliedFilter((prevState) => {
                return { ...prevState, keyword };
            });
            fetchRestaurantOfferList({ keyword, page: 1 });
            setPageState((prevState) => {
                return { ...prevState, restaurantPageState: { ...prevState.restaurantPageState, page: 1 } };
            });
        }, 1000)
    );

    const handleClickPreset = () => {
        // setActiveState((prevState) => {
        //     return { edit: !prevState.edit, selection: false, reArrage: false };
        // });
    };

    return (
        <>
            <section className='flex flex-row items-center justify-between mb-6 md:block md:mb-4'>
                <div className='flex flex-row items-center md:mb-4'>
                    <div className='w-full min-w-[375px] lg:min-w-[298px] lg:mr-2 md:min-w-0'>
                        <DefaultInputField
                            shadow='shadow-xSmall'
                            placeholder='Search presets'
                            placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                            enteredValue={handleSearch}
                        />
                    </div>

                    {showCancelDelete ? (
                        <div className='flex flex-row items-center md:w-full'>
                            <div
                                className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                onClick={handleClickCancel}>
                                <LargeTertiaryButton
                                    isDefault={false}
                                    label='Cancel'
                                    hideLabel='lg:hidden'
                                    leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                    leftIconClick={<CloseIcon stroke='#C4BEED' />}
                                />
                            </div>
                            <div
                                className='max-w-[200px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                onClick={handleClickDelete}>
                                <LargeDestructiveButton
                                    label='Delete selected'
                                    leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                    leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                    hideLabel='lg:hidden'
                                    disabled={isDisable}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            className='max-w-[156px] w-full md:max-w-[64px] ml-4 lg:ml-2 md:ml-1'
                            onClick={handleBulkSelect}>
                            <LargePrimaryButton
                                leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                label='Bulk select'
                                hideLabel='lg:hidden'
                            />
                        </div>
                    )}
                </div>

                {/* <div className='flex flex-row items-center justify-end'>
                    <div
                        className='min-w-[143px] w-full ml-2 lg:min-w-0 lg:w-16 lg:ml-2 md:w-full cursor-pointer md:ml-1'
                        onClick={handleClickPreset}>
                        <LargePrimaryButton
                            label='Create preset'
                            hideLabel='lg:hidden md:block'
                        />
                    </div>
                </div> */}
            </section>
        </>
    );
}
