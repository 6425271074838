import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { ReactComponent as CalenderIcon } from '../../../../Assets/calendar.svg';
import { ReactComponent as ScheduleIcon } from '../../../../Assets/schedule.svg';
import { ReactComponent as SuccessIcon } from '../../../../Assets/success-tick.svg';
import { ReactComponent as RejectionIcon } from '../../../../Assets/cancel.svg';
import APIV5 from '../../../../api/axios/APIV5';
import { SET_SELECTED_OUTLET_ID } from '../../../../reduxStore/types/outlet';
import { useSelector } from 'react-redux';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';

export default function ResolvedTableSection(props) {
    const { setShowResolvedComplaintPopup, setShowComplaintImage, selectedRestaurantId, selectedOutletId } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [orderResolvedDetails, setOrderResolvedDetails] = useState([]);

    const fetchOrderResolvedDetail = async () => {
        setIsLoading(true);
        try {
            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/complaints`,
                {
                    params: {
                        per_page: 10,
                        status: ['approved', 'reject'],
                    },
                }
            );
            setOrderResolvedDetails(response?.complaints);
            setIsLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderResolvedDetail();
    }, []);

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 uppercase shadow-innerShadow paragraph-overline-small text-neutral-700 pl-6 h-11'>
                            <th className='pl-6 text-left'>ORDER ID</th>
                            <th className='pl-6 text-left'>COMPLAINT REASON</th>
                            <th className='pl-6 text-left'>TOTAL AMOUNT</th>
                            <th className='pl-6 text-left'>STATUS</th>
                            <th className='pl-6 text-left'>RESOLVED AT</th>
                            <th className='pl-6 text-left'>AMOUNT REFUNDED</th>
                            <th className='pl-6 text-left'>IMAGES</th>
                        </tr>
                    </thead>

                    <tbody>
                        {isLoading && (!orderResolvedDetails?.data || orderResolvedDetails?.data.length === 0) ? (
                            <tr>
                                <td colSpan={7}>
                                    <TableLoader
                                        hasSwitch={false}
                                        columns={7}
                                    />
                                </td>
                            </tr>
                        ) : !orderResolvedDetails?.data || orderResolvedDetails?.data.length === 0 ? (
                            <tr>
                                <td colSpan={7}>
                                    <TableBlankComponentView previewText={'No Content Available'} />
                                </td>
                            </tr>
                        ) : (
                            orderResolvedDetails?.data.map((el, index) => {
                                const createdAtDate = parse(el.created_at, 'dd MMM yyyy hh:mm a', new Date());

                                const createdAt = {
                                    date: format(createdAtDate, 'dd MMM yyyy'),
                                    time: format(createdAtDate, 'hh:mm a'),
                                };
                                // const expiredAt = {
                                //     date: format(new Date(el.expired_at * 1000), 'dd MMM yyyy'),
                                //     time: format(new Date(el.expired_at * 1000), 'hh:mm a'),
                                // };
                                return (
                                    <tr
                                        className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'
                                        key={index}>
                                        <td
                                            className='pl-6 cursor-pointer text-primary-500 hover:underline'
                                            onClick={() => setShowResolvedComplaintPopup('resolved')}>
                                            {el?.order_id}
                                        </td>

                                        <td className='pl-6'>{el?.complaint_reason}</td>

                                        <td className='pl-6'>{el?.total_amount}</td>

                                        <td className='pl-6'>
                                            {el.status === 'approved' ? (
                                                <div className='flex flex-row items-center gap-1'>
                                                    <SuccessIcon />
                                                    Accepted
                                                </div>
                                            ) : (
                                                <div className='flex flex-row items-center gap-1'>
                                                    <RejectionIcon />
                                                    Rejected
                                                </div>
                                            )}
                                        </td>

                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center mb-1'>
                                                <CalenderIcon className='w-6 h-6' />
                                                <span>{createdAt.date}</span>
                                            </div>

                                            <div className='flex flex-row items-center'>
                                                <ScheduleIcon className='w-6 h-6' />
                                                <span>{createdAt.time}</span>
                                            </div>
                                        </td>

                                        <td className='pl-6'>{el?.refund_amount ? el?.refund_amount : 'N/A'}</td>

                                        {index % 2 === 0 ? (
                                            <td className='pl-6'>-</td>
                                        ) : (
                                            <td
                                                className='pl-6 cursor-pointer text-primary-500 hover:underline'
                                                onClick={() => setShowComplaintImage(true)}>
                                                Show image
                                            </td>
                                        )}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
