import React from 'react';
import { ModeCustomerName } from '../../OrderTabPage/Components/TableComponent';
import { useWindowSize } from '@uidotdev/usehooks';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { convertToCurrency, getTimeAgo, orderStatus } from '../../Helper/functions';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';

export default function TableSection(props) {
    const {
        orders,
        setShowPopup,
        paginationState,
        setPaginationState,
        setSelectedOrder,
        selectedOrder,
        isInitialLoad,
    } = props;

    const orderStatusStyle = (status) => {
        return status === 'Prepared'
            ? 'text-tertiary-800 bg-tertiary-50 border-tertiary-800'
            : status === 'Preparing'
              ? 'text-secondary-800 bg-secondary-50 border-secondary-800'
              : status === 'Pending'
                ? 'text-primary-500 bg-primary-50 border-primary-500'
                : status === 'Ready'
                  ? 'text-primary-500 bg-primary-50 border-primary-500'
                  : 'text-primary-500 bg-primary-50 border-primary-500';
    };

    const { width: screenWidth } = useWindowSize();
    const onClickOnOrder = (order) => {
        screenWidth < 1280 && setShowPopup('ordersDetails');
        setSelectedOrder({ ...order });
    };

    return (
        <>
            <div className='w-full pr-5 xl:pr-0'>
                <div className='w-full border border-neutral-300 rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden'>
                    <table className='w-full break-words'>
                        <thead>
                            <tr className='bg-neutral-50 paragraph-overline-small text-neutral-700 shadow-innerShadow h-11'>
                                <th className='text-left pl-6 min-w-[273px] pageContent:min-w-[220px]'>
                                    MODE & CUSTOMER NAME
                                </th>
                                <th className='text-left pl-6 min-w-[144px] pageContent:min-w-[130px]'>ORDER AGE</th>
                                <th className='text-left pl-6 min-w-[163px] pageContent:min-w-[155px]'>ORDER AMOUNT</th>
                                <th className='text-left pl-6 min-w-[153px] pageContent:min-w-[145px]'>ORDER STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isInitialLoad ? (
                                <tr>
                                    <td colSpan={4}>
                                        <TableLoader
                                            hasSwitch={false}
                                            columns={4} // Adjust the number of columns to match your table
                                        />
                                    </td>
                                </tr>
                            ) : orders?.length > 0 ? (
                                orders?.map((el, index) => {
                                    const statusStyle = orderStatusStyle(orderStatus(el));

                                    return (
                                        <tr
                                            key={index}
                                            className={`${
                                                el?.order_id !== selectedOrder?.order_id && `even:bg-neutral-50`
                                            } ${
                                                index !== 0 && 'border-t'
                                            } border-neutral-300 paragraph-small-regular cursor-default hover:bg-primary-100 hover:border-primary-500 hover:border-y ${
                                                el?.order_id == selectedOrder?.order_id &&
                                                `border-y bg-primary-100 border-primary-500 hover:last:border-b-0`
                                            }`}
                                            onClick={() => onClickOnOrder(el)}>
                                            <td className='h-[70px] pl-6'>
                                                <ModeCustomerName
                                                    orderingMode={el?.ordering_mode_details.tab}
                                                    customerName={el?.customer?.name}
                                                    tableName={el?.table_name}
                                                />
                                            </td>
                                            <td className='h-[70px] pl-6'>{getTimeAgo(el.placed_at)}</td>
                                            <td className='h-[70px] pl-6'>{convertToCurrency(el.net_total)}</td>
                                            <td className='h-[70px] pl-6'>
                                                <span
                                                    className={`paragraph-small-medium px-2 py-1 border rounded ${statusStyle}`}>
                                                    {orderStatus(el)}
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>
                                        <TableBlankComponentView previewText={'No Content Available'} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className='my-4'>
                    <PaginationWithNumber
                        lastPage={paginationState.lastPage}
                        selectedPage={paginationState.currentPage}
                        setSelectedPage={(value) => {
                            setPaginationState({
                                currentPage: value,
                                lastPage: paginationState.lastPage,
                                perPage: paginationState.perPage,
                            });
                        }}
                        setResultPerPage={(value) => {
                            setPaginationState({
                                currentPage: paginationState.currentPage,
                                lastPage: paginationState.lastPage,
                                perPage: value,
                            });
                        }}
                        selectedResultPerpage={paginationState.perPage}
                    />
                </div>
            </div>
        </>
    );
}
