import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import CategorySection from './CategorySection';
import DishSection from './DishSection';
import pizza1 from '../../../Assets/MediaLibrary/pizza1.png';
import pizza2 from '../../../Assets/MediaLibrary/pizza2.png';
import pizza3 from '../../../Assets/MediaLibrary/pizza3.png';
import pizza4 from '../../../Assets/MediaLibrary/pizza4.png';
import pizza5 from '../../../Assets/MediaLibrary/pizza5.png';
import pizza6 from '../../../Assets/MediaLibrary/pizza6.png';
import pizza7 from '../../../Assets/MediaLibrary/pizza7.png';
import pizza8 from '../../../Assets/MediaLibrary/pizza8.png';
import pizza9 from '../../../Assets/MediaLibrary/pizza9.png';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { useCategoryContext } from '../Components/Catalogue/Context/CategoryContext';

export default function SelectCategoriesDishesPopup(props) {
    const { handleClickClose, page, presetName } = props;

    const { categoryList, fetchCategory, paginationState, isLoading, setPaginationState, setUpdateFunction } =
        useCategoryContext();

    const [showDishesPopup, setShowDishesPopup] = useState(true);

    const handleClickDishes = () => {
        showDishesPopup && setShowDishesPopup(!showDishesPopup);
    };

    const handleClickBack = () => {
        showDishesPopup ? handleClickClose() : setShowDishesPopup(!showDishesPopup);
    };

    const isMobileScreen = IsMobileScreen();
    const [checkedItems, setCheckedItems] = useState([]);
    const [isDisable, setIsDisable] = useState(true);

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== id));
        }
    };

    useEffect(() => {
        if (checkedItems.length > 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [checkedItems]);

    const categoriesDetails = [
        {
            title: 'Sides',
            dishes: '21 dishes',
            img: pizza1,
            type: 'veg-nonVeg',
            subCategoryDetails: [
                {
                    title: 'Garlic Bread',
                    dishes: '09 dishes',
                    img: pizza2,
                    type: 'nonVeg',
                },
                {
                    title: 'French Fries Text Extended',
                    dishes: '12 dishes',
                    img: pizza3,
                    type: 'veg',
                },
            ],
        },

        {
            title: 'Pizza',
            dishes: '32 dishes',
            img: pizza4,
            type: 'veg',
        },

        {
            title: 'Burger',
            dishes: '03 dishes',
            img: pizza5,
            type: 'veg-nonVeg',
        },
        {
            title: 'Shakes',
            dishes: '07 dishes',
            img: pizza6,
            type: 'veg-nonVeg',
        },
        {
            title: 'Salad',
            dishes: '10 dishes',
            img: pizza7,
            type: 'veg-nonVeg',
        },
        {
            title: 'Pastries',
            dishes: '10 dishes',
            img: pizza8,
            type: 'veg-nonVeg',
        },
        {
            title: 'Pastries',
            dishes: '10 dishes',
            img: pizza8,
            type: 'veg-nonVeg',
        },
    ];

    const pizzaDishes = [
        {
            title: 'Mexican Green Wave Pizza Text Extended',
            variants: 'Yes(3)',
            image: pizza7,
        },
        {
            title: 'Peppy Paneer',
            variants: 'No',
        },
        {
            title: 'Deluxe Veggie',
            variants: 'Yes(3)',
        },
        {
            title: 'Paneer Makhani',
            variants: 'Yes(3)',
            image: pizza8,
        },
        {
            title: 'Cheese n Tomato',
            variants: 'Yes(3)',
        },
        {
            title: 'Veg Extraveganza',
            variants: 'Yes(3)',
            image: pizza9,
        },
        {
            title: 'Veg Extraveganza',
            variants: 'Yes(3)',
            image: pizza9,
        },
    ];

    const [isInputFieldDisable, setIsInputFieldDisable] = useState(true);

    return (
        <div
            className={`fixed bg-black bg-opacity-50 inset-0 z-[101] flex py-[18px] md:py-0 md:absolute md:z-[9] md:top-0 md:block ${
                page === 'Outlet menu details' && 'md:relative'
            }`}>
            <div
                className={`max-w-[722px] w-full rounded-xl bg-shades-50 pt-6 pb-6 px-8 md:p-4 m-auto md:m-0 h-full max-h-[991px] md:max-w-full md:max-h-full md:rounded-none md:py-4 md:pb-14 ${
                    page === 'Outlet menu details' && 'md:px-0'
                }`}>
                {!isMobileScreen ? (
                    <div className='flex flex-row items-center justify-between mb-6'>
                        <div>
                            <h3 className='paragraph-large-medium'>Select categories and dishes</h3>
                            <div className='flex flex-row items-center'>
                                <p className='paragraph-medium-italic text-neutral-500'>
                                    Select a set of categories and dishes you would like to include in the preset
                                </p>
                            </div>
                        </div>

                        <span
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </span>
                    </div>
                ) : (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 px-8 md:px-0 cursor-pointer'
                        onClick={handleClickBack}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='ml-1'>Back to {showDishesPopup ? 'catalogue presets' : 'categories'}</span>
                    </div>
                )}

                {!isMobileScreen && (
                    <div className='mb-[17px]'>
                        <DefaultInputField
                            disabled={isInputFieldDisable}
                            handleClickAddon={() => setIsInputFieldDisable((prevState) => !prevState)}
                            diableInputStyle='text-neutral-900'
                            value={presetName ?? 'None'}
                            // onChange={(value) => setPresetName(value)}
                            addon='Change'
                            boxHeight='[52px]'
                            shadow='shadow-smallDropDownShadow'
                            addonStyle='text-primary-500 paragraph-medium-underline '
                        />
                    </div>
                )}

                <div className='flex flex-row items-start mb-12 pageContent:mb-3 md:flex-col-reverse'>
                    {(showDishesPopup || !isMobileScreen) && (
                        <CategorySection
                            categoriesDetails={categoryList}
                            checkedItems={checkedItems}
                            setCheckedItems={setCheckedItems}
                        />
                    )}

                    <DishSection
                        showDishesPopup={showDishesPopup}
                        isMobileScreen={isMobileScreen}
                        handleClickDishes={handleClickDishes}
                        pizzaDishes={pizzaDishes}
                    />
                </div>

                {(page === 'Catalogue' || page === 'Catalogue presets') && (
                    <div
                        className='flex flex-row items-center justify-between w-full'
                        onClick={handleClickClose}>
                        <span className='paragraph-large-medium'>Step 2/2</span>
                        <div className='max-w-[156px] w-full'>
                            <LargePrimaryButton
                                label='Save as preset'
                                disabled={isDisable}
                            />
                        </div>
                    </div>
                )}

                {(page === 'Outlet menu details' || page === 'Catalogue Preset') && (
                    <div className='flex justify-end mt-12 pageContent:mt-3 md:fixed md:bottom-0 md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4 md:px-4'>
                        <div className='flex flex-row'>
                            <div
                                className='mr-2 md:w-full'
                                onClick={handleClickClose}>
                                <LargeDestructiveButton label={isMobileScreen ? 'Discard' : 'Discard Changes'} />
                            </div>

                            <div className='ml-2 md:w-full'>
                                <LargePrimaryButton label={isMobileScreen ? 'Save Changes' : 'Save Changes'} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
