import React from 'react';
import ContentLoader from 'react-content-loader';

const LiveOrderDetailsLoader = () => (
    <div className='w-full border border-neutral-300 rounded-xl'>
        <div className=''>
            <ContentLoader
                width={488}
                height={400}
                viewBox='0 0 488 400'
                backgroundColor='#f3f3f3'
                foregroundColor='#ecebeb'>
                {/* Order number and Order details header */}
                <rect
                    x='20'
                    y='15'
                    rx='2'
                    ry='2'
                    width='90'
                    height='16'
                />{' '}
                {/* "Order number" text */}
                <rect
                    x='410'
                    y='15'
                    rx='2'
                    ry='2'
                    width='90'
                    height='16'
                />
                {/* "Order details" text */}
                {/* Order #sjf5 and View details */}
                <rect
                    x='20'
                    y='40'
                    rx='2'
                    ry='2'
                    width='120'
                    height='22'
                />{' '}
                {/* Order #sjf5 */}
                <rect
                    x='410  '
                    y='40'
                    rx='2'
                    ry='2'
                    width='90'
                    height='22'
                />{' '}
                {/* View details */}
                {/* Ordered dishes and Quantity header */}
                <rect
                    x='20'
                    y='90'
                    rx='2'
                    ry='2'
                    width='100'
                    height='16'
                />{' '}
                {/* "Ordered dishes" text */}
                <rect
                    x='410'
                    y='90'
                    rx='2'
                    ry='2'
                    width='90'
                    height='16'
                />{' '}
                {/* "Quantity" text */}
                {/* Dish name and quantity */}
                <rect
                    x='20'
                    y='115'
                    rx='2'
                    ry='2'
                    width='200'
                    height='22'
                />{' '}
                {/* Dish name */}
                <rect
                    x='440'
                    y='115'
                    rx='2'
                    ry='2'
                    width='30'
                    height='22'
                />{' '}
                {/* Quantity number */}
                {/* Total bill amount */}
                <rect
                    x='20'
                    y='170'
                    rx='2'
                    ry='2'
                    width='160'
                    height='22'
                />{' '}
                {/* "Total bill amount" text */}
                <rect
                    x='410'
                    y='170'
                    rx='2'
                    ry='2'
                    width='90'
                    height='22'
                />{' '}
                {/* Amount */}
                {/* Buttons */}
                <rect
                    x='35'
                    y='220'
                    rx='8'
                    ry='8'
                    width='215'
                    height='45'
                />{' '}
                {/* Cancel order button */}
                <rect
                    x='260'
                    y='220'
                    rx='8'
                    ry='8'
                    width='215'
                    height='45'
                />{' '}
                {/* Accept order button */}
                <rect
                    x='32'
                    y='280'
                    rx='8'
                    ry='8'
                    width='450'
                    height='45'
                />{' '}
                {/* Rider assignment button */}
            </ContentLoader>
        </div>
    </div>
);

export default LiveOrderDetailsLoader;
