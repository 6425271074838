import React, { useRef, useEffect, useState, useMemo } from 'react';
import { ReactComponent as OrderRatingIcon } from '../../../Assets/order-ratings.svg';
import ListViewRider from '../../../Components/ListView/ListViewRider';
import { IsMobileScreen } from '../../../Constants/Constants';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import ListViewLoader from '../../../Components/ContentLoader/ListViewLoader';
import { TableBlankComponentView } from '../../../Components/BlankComponent/TableBlankComponentView';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';

const Table = (props) => {
    const {
        handleClickName,
        isEditTable,
        setPopupRiderDetails,
        showCheckbox,
        waiterDetails,
        paginationChanged,
        isLoading,
        handleCheckboxChange,
        checkedItems,
        fetchEditWaiterDetails,
        setAddOrEdit,
        setCheckedItems,
        appliedFilter,
    } = props;

    function formatDate(dateString) {
        if (typeof dateString !== 'string' || !dateString.includes('-')) {
            return 'Invalid Date';
        }

        const [day, month, year] = dateString.split('-').map(Number);

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return 'Invalid Date';
        }

        const date = new Date(year, month - 1, day);

        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        const monthNames = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const monthName = monthNames[date.getMonth()];
        const formattedDate = `${day} ${monthName} ${year}`;

        return formattedDate;
    }

    const isMobileScreen = IsMobileScreen();
    const [isHeaderIndeterminate, setIsHeaderIndeterminate] = useState(false);

    const areAllCurrentPageItemsSelected = useMemo(() => {
        const currentPageIds = waiterDetails.map((notification) => notification.id);
        const selectedCurrentPageItems = currentPageIds.filter((id) => checkedItems.includes(id));

        setIsHeaderIndeterminate(
            selectedCurrentPageItems.length > 0 && selectedCurrentPageItems.length < currentPageIds.length
        );

        return currentPageIds.length > 0 && selectedCurrentPageItems.length === currentPageIds.length;
    }, [waiterDetails, checkedItems]);

    const selectAll = () => {
        const currentPageIds = waiterDetails.map((waiter) => waiter.id);

        if (areAllCurrentPageItemsSelected) {
            // Deselect all items on the current page
            const newCheckedItems = checkedItems.filter((id) => !currentPageIds.includes(id));
            setCheckedItems(newCheckedItems);
        } else {
            // Select all items on the current page
            const newIds = currentPageIds.filter((id) => !checkedItems.includes(id));
            setCheckedItems([...checkedItems, ...newIds]);
        }
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    {isLoading || paginationChanged ? (
                        <table>
                            <tbody>
                                <TableLoader
                                    hasSwitch={false}
                                    columns={6}
                                />
                            </tbody>
                        </table>
                    ) : (
                        <table className='w-full break-words'>
                            <thead>
                                <tr className='bg-neutral-50 text-left paragraph-overline-small text-neutral-700 shadow-innerShadow justify-center h-11'>
                                    <th className='px-6 min-w-[230px]'>
                                        <div className='flex flex-row'>
                                            {showCheckbox && !isEditTable && (
                                                <div className='mr-4 cursor-pointer'>
                                                    <CheckBoxWithoutLabels
                                                        onChange={selectAll}
                                                        isChecked={areAllCurrentPageItemsSelected}
                                                        isIndeterminate={isHeaderIndeterminate}
                                                    />
                                                </div>
                                            )}
                                            WAITER NAME
                                        </div>
                                    </th>
                                    <th className='px-6 min-w-[230px]'>MOBILE NUMBER</th>
                                    <th className='px-6 min-w-[206px]'>JOIN DATE</th>
                                    <th className='px-6 min-w-[212px]'>Email ID</th>
                                    <th className='px-6 min-w-[194px]'>Table Number</th>
                                    {/* <th className='px-6 min-w-[192px]'>AVG. WAITER RATING</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {waiterDetails && waiterDetails.length > 0 ? (
                                    waiterDetails.map((el, index) => {
                                        return (
                                            <tr
                                                className='paragraph-small-regular border-t first:border-none even:bg-neutral-50 border-neutral-300 h-[70px] justify-center'
                                                key={index}>
                                                <td
                                                    className={`px-6 ${isEditTable ? 'cursor-pointer' : ''}`}
                                                    onClick={() => isEditTable && setPopupRiderDetails(el)}>
                                                    <div className='flex flex-row items-center'>
                                                        {isEditTable && !showCheckbox && (
                                                            <div
                                                                className='mr-4 cursor-pointer'
                                                                onClick={() => {
                                                                    fetchEditWaiterDetails(el?.id);
                                                                    setAddOrEdit('edit');
                                                                }}>
                                                                <EditIcon />
                                                            </div>
                                                        )}
                                                        {showCheckbox && !isEditTable && (
                                                            <div className='mr-4 cursor-pointer'>
                                                                <CheckBoxWithoutLabels
                                                                    onChange={(isChecked) =>
                                                                        handleCheckboxChange(el.id, isChecked)
                                                                    }
                                                                    isChecked={checkedItems.includes(el.id)}
                                                                />
                                                            </div>
                                                        )}
                                                        <span
                                                            className='cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                            onClick={() => handleClickName(el?.id)}>
                                                            {el?.name}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className='px-6'>{el?.mobile_number}</td>
                                                {/* <td className='px-6'>{formatDate(el?.join_date)}</td> */}
                                                <td className='px-6'>{el?.join_date}</td>
                                                <td className='px-6'>{el?.email} </td>
                                                <td className='px-6'>
                                                    {el.waiter_tables
                                                        ?.map((item) => item.outlet_table?.internal_name)
                                                        .filter(Boolean)
                                                        .join(', ')}
                                                </td>
                                                {/* <td className='px-6'>
                                                    <div className='flex flex-row items-center'>
                                                        <OrderRatingIcon
                                                            height={24}
                                                            width={24}
                                                            className='mr-1'
                                                            fill={
                                                                el?.avg_rider_rating > 4
                                                                    ? '#EBF6F5'
                                                                    : el?.avg_rider_rating >= 3
                                                                      ? '#FFF5E3'
                                                                      : '#FEF2F2'
                                                            }
                                                            stroke={
                                                                el?.avg_rider_rating > 4
                                                                    ? '#3D8C82'
                                                                    : el?.avg_rider_rating >= 3
                                                                      ? '#FFA704'
                                                                      : '#EF4444'
                                                            }
                                                        />
                                                        {el?.avg_rider_rating ? el?.avg_rider_rating : '0.00'}
                                                    </div>
                                                </td> */}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={6}>
                                            <TableBlankComponentView previewText={'No Content Available'} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            ) : isLoading || paginationChanged ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='mb-12 mt-3 md:mt-1'>
                    {showCheckbox && (
                        <div className='ml-4 cursor-pointer flex flex-row'>
                            {showCheckbox && !isEditTable && (
                                <div className='cursor-pointer'>
                                    <CheckBoxWithoutLabels onChange={selectAll} />
                                </div>
                            )}
                            <span className='ml-2 mb-4'>Select all</span>
                        </div>
                    )}

                    {waiterDetails && waiterDetails.length > 0 ? (
                        waiterDetails.map((el, index) => (
                            <ListViewRider
                                key={el?.id}
                                riderId={el?.id}
                                riderName={el?.name}
                                // riderRating={el?.avg_rider_rating}
                                mobileNumber={el?.mobile_number}
                                // deliveryRadius={el?.delivery_radius}
                                joinDate={formatDate(el?.join_date)}
                                handleClickViewDetail={handleClickName}
                                showCheckbox={showCheckbox}
                                isEditTable={isEditTable}
                                handleCheckboxChange={handleCheckboxChange}
                                checkedItems={checkedItems}
                                setCheckedItems={setCheckedItems}
                                index={index}
                                fetchEditWaiterDetails={fetchEditWaiterDetails}
                                setAddOrEdit={setAddOrEdit}
                            />
                        ))
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}
        </>
    );
};

export default Table;
