import React from 'react';
import { LargePrimaryButton } from '../../../../../Components/Buttons/Button';

const AlreadyLinkPopup = ({ errorMessage, onClose, outletList }) => {
    const getOutletName = (outletId) => {
        const outlet = outletList.find((el) => el.outletId === outletId);
        return outlet ? outlet.displayName : 'Unknown Outlet';
    };

    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex p-4 md:p-0 md:absolute md:z-[9] overflow-auto md:overflow-visible'>
            <div className='max-w-[830px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:mt-0 md:w-full md:p-4 lg:max-w-[439px] md:max-w-full md:rounded-none'>
                <div className='bg-white text-black p-4 rounded-lg'>
                    <p className='text-2xl font-semibold'>Sorry</p>
                    <p className='text-xl'>
                        {errorMessage &&
                            Object.keys(errorMessage).map((key) => {
                                const outletId = key.split('.')[0].split('[')[1]; // Extract outlet ID (if present)
                                const outletName = outletId ? getOutletName(parseInt(outletId, 10)) : 'outlets'; // Get the name or generic message
                                return (
                                    <p
                                        className='text-red-500 mb-2'
                                        key={key}>
                                        {outletName} : {errorMessage[key]}
                                    </p>
                                );
                            })}
                    </p>
                    <div className='flex flex-row justify-end gap-4 mt-4'>
                        <div
                            className='cursor-pointer'
                            onClick={onClose}>
                            <LargePrimaryButton
                                label='Okay'
                                hideLabel='xl:hidden lg:block mobile:hidden'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AlreadyLinkPopup;
