import React from 'react';
import ContentLoader from 'react-content-loader';

export default function FilterLoader() {
    return (
        <ContentLoader
            width='100%'
            className='rounded-lg'>
            <rect
                x='80'
                y='55'
                rx='4'
                ry='4'
                width='230'
                height='41'
            />
        </ContentLoader>
    );
}

export const DatePickerLoader = () => {
    return (
        <ContentLoader
            width='90%'
            height='56px'
            className='rounded-lg border-2 flex flex-row items-center'>
            <rect
                x='10'
                y='13'
                rx='4'
                ry='4'
                width='28'
                height='28'
            />
            <rect
                x='50'
                y='6'
                rx='8'
                ry='8'
                width='210'
                height='40'
            />
        </ContentLoader>
    );
};
