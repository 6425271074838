import React, { useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import WaitersDetailsTopLoader from './WaitersDetailsTopLoader';
import ListViewWaiterDetailTop from './ListViewWaiterDetailTop';

const WaiterDetailsTop = (props) => {
    const { goToRiderPage, waiterDetails, isRiderTopLoading } = props;
    console.log(`asdfasdfasd ==>`, waiterDetails);
    const isMobileScreen = IsMobileScreen();

    function formatDate(dateString) {
        const [day, month, year] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        const monthNames = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const monthName = monthNames[date.getMonth()];
        const formattedDate = `${day} ${monthName} ${year}`;

        return formattedDate;
    }

    return (
        <>
            {isMobileScreen && (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={goToRiderPage}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='ml-1'>Back to Waiter</span>
                </div>
            )}

            {!isMobileScreen ? (
                isRiderTopLoading ? (
                    <WaitersDetailsTopLoader />
                ) : (
                    <div className='mt-4 items-center flex flex-row border-b pb-4 border-neutral-300 xl:items-start'>
                        {/* <img
                            src={
                                waiterDetails && waiterDetails?.profile_image_url
                                    ? waiterDetails?.profile_image_url
                                    : 'N/A'
                            }
                            alt=''
                            className='h-[120px] w-[120px]'
                        /> */}
                        <div className='w-full pr-[9px] py-1.5 pl-6 xl:py-0 lg:pl-4 lg:pr-0'>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-row xl:flex-col'>
                                    <div className='mr-[103px]'>
                                        <div className='mt-1.5 pb-1.5 lg:mt-0'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>Name:</span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {waiterDetails && waiterDetails.name ? `${waiterDetails.name}` : 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Waiter's id:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {waiterDetails && waiterDetails?.id ? waiterDetails?.id : 'N/A'}
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Mobile number:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {waiterDetails && waiterDetails?.mobile_number
                                                    ? waiterDetails?.mobile_number
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Table Section:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {waiterDetails && waiterDetails?.sections
                                                    ? waiterDetails?.sections.name
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        {/* <div className='mt-1.5 pb-1.5 flex flex-row lg:pb-0'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400 min-w-[112px]'>
                                                Rider address:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {waiterDetails && waiterDetails?.rider_address
                                                    ? waiterDetails?.rider_address
                                                    : 'N/A'}
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='flex flex-row xl:flex-col'>
                                    <div className='mr-[103px] xl:mr-0'>
                                        <div className='mt-1.5 pb-1.5 lg:mt-0'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>Email:</span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {waiterDetails && waiterDetails?.email
                                                    ? `${waiterDetails?.email}`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Table Sub Section:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {waiterDetails && waiterDetails?.sub_sections
                                                    ? waiterDetails?.sub_sections?.name
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>

                                    <div className='mt-1.5 pb-1.5'>
                                        <span className='paragraph-medium-semi-bold text-neutral-400'>Table Name:</span>
                                        <span className='ml-1 paragraph-medium-regular'>
                                            {waiterDetails && waiterDetails?.tables
                                                ? waiterDetails?.tables?.[0]?.name
                                                : 'N/A'}
                                        </span>
                                    </div>
                                    {/* <div className='mt-1.5 pb-1.5'>
                                        <span className='paragraph-medium-semi-bold text-neutral-400'>
                                            Rider avg. rating:
                                        </span>
                                        <span className='ml-1 paragraph-medium-regular'>
                                            {waiterDetails && waiterDetails?.average_rating
                                                ? waiterDetails?.average_rating
                                                : '0.0'}
                                        </span>
                                    </div> */}
                                    {/* <div className='mt-1.5 pb-1.5'>
                                        <span className='paragraph-medium-semi-bold text-neutral-400'>
                                            Rider Extra Delivery rate:
                                        </span>
                                        <span className='ml-1 paragraph-medium-regular'>
                                            {waiterDetails && waiterDetails?.extra_delivery_rate
                                                ? waiterDetails?.extra_delivery_rate
                                                : '0.0'}
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div className='border-b pb-4 mb-4 border-neutral-300'>
                    <ListViewWaiterDetailTop
                        riderName={
                            waiterDetails && waiterDetails.first_name && waiterDetails.last_name
                                ? `${waiterDetails.first_name} ${waiterDetails.last_name}`
                                : 'N/A'
                        }
                        riderRating={waiterDetails && waiterDetails?.average_rating ? waiterDetails?.average_rating : 0}
                        billName={waiterDetails && waiterDetails?.rider_name ? waiterDetails?.rider_name : 'N/A'}
                        mobileNumber={
                            waiterDetails && waiterDetails?.mobile_number ? waiterDetails?.mobile_number : 'N/A'
                        }
                        ordersDelivered={
                            waiterDetails && waiterDetails?.orders_delivered ? waiterDetails?.orders_delivered : 'N/A'
                        }
                        deliveryRadius={
                            waiterDetails && waiterDetails?.delivery_radius ? waiterDetails?.delivery_radius : 'N/A'
                        }
                        deliveryRate={
                            waiterDetails && waiterDetails?.delivery_rate ? waiterDetails?.delivery_rate : 'N/A'
                        }
                        joinDate={
                            waiterDetails && waiterDetails?.join_date ? formatDate(waiterDetails?.join_date) : 'N/A'
                        }
                        lastOrder={
                            waiterDetails && waiterDetails?.latest_rider_order
                                ? formatDate(waiterDetails?.latest_rider_order)
                                : 'N/A'
                        }
                        riderAddress={
                            waiterDetails && waiterDetails?.rider_address ? waiterDetails?.rider_address : 'N/A'
                        }
                    />
                </div>
            )}
        </>
    );
};

export default WaiterDetailsTop;
