import { useWindowSize } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function DishDetailsList(props) {
    const { categoryDetails, isSubcategory } = props;

    const [showDetails, setShowDetails] = useState(true);

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    const formateTheme = () => {
        const theme = categoryDetails?.theme?.replaceAll('_', ' ');
        return theme?.charAt(0)?.toUpperCase() + theme?.slice(1);
    };

    const formateTile = () => {
        const tile = categoryDetails?.tile_details_position?.replaceAll('-', ' ');
        return tile?.charAt(0)?.toUpperCase() + tile?.slice(1);
    };

    return (
        <>
            <div
                className={`border border-neutral-300 px-4 py-[15px] rounded-lg w-full ${
                    !showDetails && 'cursor-pointer'
                }`}
                onClick={handleCardClick}>
                <div className='flex flex-row justify-between cursor-pointer'>
                    <span className='paragraph-medium-medium'>Category details</span>
                    <span
                        className='paragraph-medium-regular text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <div className={`${isSubcategory ? 'mt-6' : 'mt-2'}`}>
                        <div className='flex flex-row items-start mb-2'>
                            {!isSubcategory && (
                                <img
                                    src={categoryDetails?.image?.image_url}
                                    alt={categoryDetails?.image?.alternative_title}
                                    className='w-[144px] h-[96px] mr-3 rounded'
                                />
                            )}
                            <div className='flex flex-col'>
                                <div className='flex flex-row mb-4'>
                                    <span className='paragraph-small-regular text-neutral-500 mr-2'>Category:</span>
                                    <span className='paragraph-small-regular'>
                                        {categoryDetails?.translations?.title?.[selectedLanguage]}
                                    </span>
                                </div>
                                <div className='flex flex-row mr-4'>
                                    <span className='paragraph-small-regular text-neutral-500 mr-2 min-w-[94px]'>
                                        Internal name:
                                    </span>
                                    <span className='paragraph-small-regular break-words'>
                                        {categoryDetails?.internal_name}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className=''>
                            <div className='flex flex-row items-start pb-4'>
                                <span className='paragraph-small-regular text-neutral-500 mr-2'>Description:</span>
                                <span className='paragraph-small-regular'>
                                    {categoryDetails?.translations?.description?.[selectedLanguage]
                                        ? categoryDetails?.translations?.description?.[selectedLanguage]
                                        : 'No Description'}
                                </span>
                            </div>

                            <div className='flex flex-row items-center mb-4'>
                                <span className='paragraph-small-regular text-neutral-500 mr-2'>Tile position:</span>
                                <span className='paragraph-small-regular'>{formateTile()}</span>
                            </div>

                            <div className='flex flex-row items-center mb-2'>
                                <span className='paragraph-small-regular text-neutral-500 mr-2'>Theme:</span>
                                <span className='paragraph-small-regular'>{formateTheme()}</span>
                            </div>

                            <div className='flex flex-row items-start'>
                                <span className='paragraph-small-regular text-neutral-500 mr-2 mt-2'>
                                    Category visibility:
                                </span>
                                <div className='flex flex-col items-start'>
                                    {categoryDetails?.ordering_modes?.map((el, index) => (
                                        <span
                                            className='paragraph-small-regular mt-2'
                                            key={index}>
                                            {el.restaurant_ordering_mode_name}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
